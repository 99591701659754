import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Auth/Login';
import Home from './components/Home/Home';
import Register from './components/Auth/Register';
import ForgotPassword from './components/Auth/ForgotPassword'; // Import ForgotPassword component
import { AuthProvider } from './AuthContext';
import Profile from './components/Profile/Profile';
import ProtectedRoute from './components/ProtectedRoute';
import PricingBilling from './Billing/PricingBilling';
import Success from './Billing/Success';
import Cancel from './Billing/Cancel';
import ModelBusiness from './components/Business/ModelbusinessNew';
import DownloadHistory from './components/History/DownloadHistory';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />          
          <Route path="/forgot-password" element={<ForgotPassword />} />          
          <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/pricing-billing" element={<ProtectedRoute><PricingBilling /></ProtectedRoute>} />
          <Route path="/success" element={<ProtectedRoute><Success /></ProtectedRoute>} />
          <Route path="/cancel" element={<ProtectedRoute><Cancel /></ProtectedRoute>} />
          <Route path="/model-business" element={<ProtectedRoute><ModelBusiness /></ProtectedRoute>} />
          <Route path="/downloads" element={<ProtectedRoute><DownloadHistory /></ProtectedRoute>} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
