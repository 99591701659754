import React, { useState} from 'react';
import Sidebar from '../UI/Sidebar';
import AdComponent from '../AdComponent';
import './Home.css';
import UniqueFloatingChat from './UniqueFloatingChat';
import Modal from 'react-modal';
import { FaInstagram, FaFacebookF, FaYoutube, FaTiktok } from 'react-icons/fa';
import { SiX } from 'react-icons/si';

Modal.setAppElement('#root');

const Card = ({ title, content }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <div className="card">
      <div className="card-header">{title}</div>
      <div className="card-content">
        <p>{Array.isArray(content) ? content[0] : content.substring(0, 150) + '...'}</p>
      </div>
      <div className="card-footer">
        <button onClick={openModal}>Leer más</button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-content"
        overlayClassName="modal-overlay"
        contentLabel={title}
      >
        <h2>{title}</h2>
        <div className="modal-body">
          {Array.isArray(content) ? (
            content.map((item, index) => (
              <React.Fragment key={index}>
                <p>{item}</p>
                {index < content.length - 1 && <hr className="modal-divider" />}
              </React.Fragment>
            ))
          ) : (
            <p>{content}</p>
          )}
        </div>
        <div className="modal-footer">
          <button onClick={closeModal} className="closebutton">Cerrar</button>
        </div>
      </Modal>
    </div>
  );
};

const LandingPage = () => {
  const cardData = [
    {
      title: "Misión y visión",
      content: "Nuestra misión es simplificar y enriquecer el mundo de los negocios a través de servicios y herramientas vanguardistas. Aspiramos a ser líderes en la innovación y eficiencia empresarial, proporcionando soluciones que no solo optimicen procesos, sino que también impulsen el crecimiento y desarrollo sostenible de las empresas. Creemos en la transformación digital como motor de cambio y estamos comprometidos en ofrecer tecnologías avanzadas que faciliten la toma de decisiones, mejoren la productividad y fomenten un entorno empresarial dinámico y competitivo."
    },
    {
      title: "Estrategia de comercialización",
      content: "Nuestra estrategia se centra en la comprensión profunda de las necesidades del mercado, utilizando y adaptando la Inteligencia Artificial a nuestros servicios para ofrecer soluciones que realmente agreguen valor a nuestros clientes."
    },
    {
      title: "Proyecciones económicas y financieras",
      content: "AIBUC proyecta un crecimiento sostenido, basado en la expansión de nuestra cartera de servicios y la penetración en nuevos mercados."
    },
    {
      title: "Responsabilidad social y ambiental",
      content: "La empresa plantea generar dos versiones, una de las cuales será gratuita. De este modo la herramienta podrá llegar a cada persona y emprendedor, independientemente de su realidad economía. De acuerdo a la responsabilidad ambiental, la empresa fue creada en forma digital y trabaja desde la nube."
    },
    {
      title: "El objetivo de tu plan de negocios",
      content: "El objetivo del plan de negocios es proveer información acerca de lo que la organización es, a dónde quiere llegar y cómo lo hará. Constituye un instrumento que debe servir de base para la toma de decisiones y, como tal, que atienda las necesidades de información de distintos grupos de interés. En este marco conceptual se considerará, cualquiera sea el ente emisor, como usuarios tipo: Empresarios, emprendedores o quienes tienen la responsabilidad de gestionar organizaciones o unidades de negocios, para tener información útil para la toma de decisiones. Acreedores actuales o potenciales, interesados en evaluar la capacidad del ente de afrontar las obligaciones asumidas. Inversores actuales y potenciales, interesados en conocer el riesgo inherente a su inversión y tomar las decisiones relacionadas con misma. Los clientes, para valorar la estabilidad de su proveedor, especialmente cuando tenga algún tipo de dependencia comercial del mismo. Distintos órganos públicos que requieran información sobre la situación presente y futura la organización. Todo otro grupo interesado en conocer el riesgo de continuidad de la organización."
    },
    {
      title: "Puntos clave de tu Plan de negocios",
      content: [
        "1. Resumen Ejecutivo",
        "2. Descripción del negocio",
        "3. Producto o servicio",
        "4. Descripción del sector",
        "5. Estrategias de comercialización",
        "6. Gestión y personal",
        "7. Protección y normativa",
        "8. Plan de puesta en marcha",
        "9. Análisis de riesgo",
        "10. Plan económico financiero"
      ]
    },
    {
      title: "Propuesta de Valor",
      content: "El común denominador de los emprendedores, empresas y hasta consultores, suelen tener 3 opciones a la hora de emprender, generar un nuevo proyecto, o ayudar a otro a hacerlo. Ser idóneo en el área, contratar un profesional que lo asista, o lanzarse a la suerte. En cualquiera de estas tres formas las personas necesitan generar un plan de negocios que en base a sus conocimientos y pericia en cada área será mayor o menor la posibilidad de éxito en el proyecto. La propuesta de valor que brinda AiBUC es una aplicación en donde a través de un chat con Inteligencia Artificial y pasando por 10 puntos secuenciales, se le puede generar un plan de negocio al usuario de manera ágil y rápida en pocos minutos. A su vez, la posibilidad de iterarlo. De esta forma un proceso tedioso que suele quedar en manos solo de profesionales idóneos en el área podría generar un antes y un después a la hora de la creación de un nuevo negocio o proyecto. El principal beneficio será que por un lado a los inexpertos le dará una herramienta gratuita para poder ordenar sus proyectos, iterarlos, reducir el riesgo a fallar, y por sobre todo poder llevarlos a cabo con un plan. Por el otro lado, de cara a su uso profesional en la versión experto, se brindará una herramienta clave para poder agilizar la labor de la creación e ideación del plan, pudiendo de esa forma encauzar todo su esfuerzo y uso de horas en actividades precisas de creatividad y utilizar su conocimiento para iterar dicho plan y eficientizarlo al máximo. Además, el experto con dicha herramienta podrá hacer frente a muchos más casos de éxito simultáneamente."
    },
    {
      title: "Socios claves",
      content: [
       " @mynameisanna.3d",
        "https://www.instagram.com/mynameisanna.3d/",
        "https://anaclo3d.wixsite.com/annad3",
        "Flipando.ai"
      ]
    },
  ];

  return (
    <div className="landing-page">
      <Sidebar />
      <div className="main-content">
        <div className="content">
          <AdComponent position="top-ad" />
          <section className="welcome-section">
            <h1>Bienvenidos a AIBUC</h1>
            <p>
              La nueva empresa AiBUC LLC (A business case) ubicada en Tampa, Estado de Florida, ha nacido con el objetivo de lograr facilitar la vida y las tareas tanto de pequeños emprendedores, estudiantes y/o consultores como de grandes empresas o las mayores consultoras del mundo.
              AiBUC es una empresa innovadora que busca transformar el panorama de los negocios ofreciendo principalmente la creación de un plan de negocios integral para un proyecto particular en una empresa o la creación e ideación desde cero hasta puesta en marcha de una nueva empresa.
            </p>
          </section>
          
          <section className="business-case">
            <h2>A Business Case</h2>
            <div className="card-grid">
              {cardData.map((card, index) => (
                <Card key={index} title={card.title} content={card.content} />
              ))}
            </div>
          </section>
        </div>
        <footer className="social-footer">
          <div className="social-icons">
            <a href="https://x.com/AibucLLC" target="_blank" rel="noopener noreferrer" className="social-icon">
              <SiX />
            </a>
            <a href="https://www.instagram.com/aibucllc/" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaInstagram />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61562634371979" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaFacebookF />
            </a>
            <a href="https://www.youtube.com/@AiBUCLLC" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaYoutube />
            </a>
            <a href="https://www.tiktok.com/@aibucllc" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaTiktok />
            </a>
          </div>
        </footer>
      </div>
      <UniqueFloatingChat />
    </div>
  );
}

export default LandingPage;