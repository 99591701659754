import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { FaPaperPlane, FaTimes, FaComments } from 'react-icons/fa';
import './UniqueFloatingChat.css';

const UniqueFloatingChat = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const chatBodyRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openChat: () => setIsOpen(true)
  }));

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() === '') return;

    const newMessage = { role: 'user', content: inputMessage };
    setMessages(prev => [...prev, newMessage]);
    setInputMessage('');
    await processMessage(inputMessage);
  };

  const processMessage = async (message) => {
    setIsProcessing(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/assistant/message?faqs=true`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message, context: messages }),
      });
      const responseData = await response.json();
      setMessages(prev => [...prev, { role: 'ai', content: responseData.message }]);
    } catch (error) {
      console.error('Error processing message:', error);
      setMessages(prev => [...prev, { role: 'ai', content: 'Lo siento, hubo un error al procesar tu mensaje.' }]);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <>
      <div className="unique-float-button" onClick={toggleChat}>
        {isOpen ? <FaTimes /> : <FaComments />}
      </div>
      {isOpen && (
        <div className="unique-float-chat">
          <div className="unique-float-header">
            <h2>Asistente Virtual</h2>
          </div>
          <div className="unique-float-body" ref={chatBodyRef}>
            {messages.map((msg, index) => (
              <div key={index} className={`unique-float-message ${msg.role}-message`}>
                <p>{msg.content}</p>
              </div>
            ))}
          </div>
          <form className="unique-float-input-container" onSubmit={handleSend}>
            <input
              type="text"
              className="unique-float-input"
              placeholder="Escribe tu pregunta aquí..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              disabled={isProcessing}
            />
            <button className="unique-float-send-button" type="submit" disabled={isProcessing}>
              <FaPaperPlane />
            </button>
          </form>
          {isProcessing && (
            <div className="unique-float-loading">
              <div className="unique-float-spinner"></div>
            </div>
          )}
        </div>
      )}
    </>
  );
});

export default UniqueFloatingChat;