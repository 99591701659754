import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaCog, FaCreditCard, FaDownload, FaSignOutAlt, FaSignInAlt, FaBars, FaChevronLeft } from 'react-icons/fa';
import { SiGoogledocs } from "react-icons/si";
import { BiWorld } from "react-icons/bi";
import './Sidebar.css';
import { useAuth } from '../../AuthContext';

const Sidebar = () => {
  const { isLoggedIn, handleLogout } = useAuth();
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchSubscription = async () => {
      if (isLoggedIn) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscriptions/active`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });

          if (!response.ok) {
            throw new Error('Error al obtener la suscripción');
          }

          const data = await response.json();
          setSubscription(data);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };
    fetchSubscription();
  }, [isLoggedIn]);

  const handleLoginClick = () => {
    navigate('/login');
  };

  const getMembershipText = () => {
    if (!subscription) {
      return 'Upgrade to Expert';
    }
    if (subscription.status === 'active') {
      return 'Membresía';
    }
    return 'Renovar Membresía';
  };

  const membershipText = getMembershipText();

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div className="sidebar-header">
        <div className="sidebar-toggle" onClick={toggleSidebar}>
          {isExpanded ? <FaChevronLeft /> : <FaBars />}
        </div>
        {isExpanded && (
          <>
            <div className="logo"><img src='/aibuc.png'></img></div>
            <Link className="sidebar-language" to="">
              <BiWorld className="icon" />
            </Link>
          </>
        )}
      </div>
      <ul className="sidebar-list">
        <li>
          <Link className="sidebar-link" to="/home">
            <FaHome className="icon" />
            {isExpanded && <span>Inicio</span>}
          </Link>
        </li>
        <li>
          <Link className="sidebar-link" to="/model-business">
            <SiGoogledocs className="icon" />
            {isExpanded && <span>Crear mi business plan</span>}
          </Link>
        </li>
        <li>
          <Link className="sidebar-link" to="/downloads">
            <FaDownload className="icon" />
            {isExpanded && <span>Mis business plans</span>}
          </Link>
        </li>
        <li>
          <Link className="sidebar-link" to="/profile">
            <FaCog className="icon" />
            {isExpanded && <span>Ajustes</span>}
          </Link>
        </li>
        <li>
          <Link className="sidebar-link sidebar-link-expert" to="/pricing-billing">
            <FaCreditCard className="icon" />
            {isExpanded && <span>{membershipText}</span>}
          </Link>
        </li>
      </ul>
      <div className="sidebar-footer">
        {isLoggedIn ? (
          <button onClick={handleLogout} className="sidebar-button">
            <FaSignOutAlt className="sidebar-icon" />
            {isExpanded && <span>Logout</span>}
          </button>
        ) : (
          <button onClick={handleLoginClick} className="sidebar-button">
            <FaSignInAlt className="sidebar-icon" />
            {isExpanded && <span>Login</span>}
          </button>
        )}
      </div>
    </div>
  );
};

export default Sidebar;