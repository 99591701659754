import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Sidebar from '../UI/Sidebar';
import './DownloadHistory.css';
import PreviewModal from '../Business/PreviewModal';


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 800,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    flexWrap: 'wrap',
  },
  filterInput: {
    marginRight: theme.spacing(2),
    flex: 1,
    backgroundColor: '#fff',
    color: '#000',
  },
  content: {
    marginLeft: 'auto',
    padding: theme.spacing(3),
    backgroundColor: '#fff',
    color: '#000',
    minHeight: '100vh',
    width: 'calc(100% - 220px)',
  },
  tableHeader: {
    backgroundColor: '#f5f5f5',
    color: '#000',
  },
  tableSortLabel: {
    '&:hover, &:active': {
      color: '#000',
    },
  },
  downloadLink: {
    color: '#000',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  filterWrapper: {
    display: 'flex',
    width: '100%',
  },
  inputLabel: {
    color: '#000',
  },
  input: {
    color: '#000',
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: 'auto',
    maxHeight: '80vh',
  },
  textarea: {
    width: '100%',
    minHeight: '300px',
  },
  saveButton: {
    marginTop: theme.spacing(2),
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  buttonMargin: {
    marginLeft: theme.spacing(2),
  },
}));

const DownloadHistory = () => {
  const classes = useStyles();
  const [downloads, setDownloads] = useState([]);
  const [filteredDownloads, setFilteredDownloads] = useState([]);
  const [nameFilter, setNameFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  const [open, setOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentContent, setDocumentContent] = useState('');
  const [saving, setSaving] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchDownloadHistory();
  }, []);

  useEffect(() => {
    let filtered = downloads;

    if (nameFilter) {
      filtered = filtered.filter(download =>
        download.name.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }

    if (dateFilter) {
      filtered = filtered.filter(download =>
        new Date(download.created_at).toLocaleDateString().includes(dateFilter)
      );
    }

    setFilteredDownloads(filtered);
  }, [nameFilter, dateFilter, downloads]);

  const fetchDownloadHistory = async () => {
    try {
      if (!token) {
        setSnackbarMessage('Sesión expirada. Por favor, inicie sesión nuevamente.');
        setSnackbarOpen(true);
        setTimeout(() => window.location.href = '/login', 3000);
        return;
      }
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/plans`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setDownloads(data.plans);
        setFilteredDownloads(data.plans);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch download history:', errorData);
        setSnackbarMessage(errorData.detail || 'Error al obtener el historial de descargas.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error fetching download history:', error);
      setSnackbarMessage('Error al obtener el historial de descargas.');
      setSnackbarOpen(true);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedDownloads = filteredDownloads.sort((a, b) => {
    if (orderBy === 'date') {
      return (order === 'asc' ? new Date(a.date) - new Date(b.date) : new Date(b.date) - new Date(a.date));
    } else {
      return (order === 'asc' ? a.project_name.localeCompare(b.project_name) : b.project_name.localeCompare(a.project_name));
    }
  });

  const handleOpen = async (downloadId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/plans/${downloadId}/content`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setDocumentContent(data.content);
        setSelectedDocument(downloadId);
        setOpen(true);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch document content:', errorData);
        setSnackbarMessage(errorData.detail || 'Error al obtener el contenido del documento.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setSnackbarMessage('Error al obtener el contenido del documento.');
      setSnackbarOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDocument(null);
    setDocumentContent('');
  };

  const handleSave = async (content) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/plans/${selectedDocument}/content`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ content: content }),
      });
      if (!response.ok) {
        throw new Error('Error saving document');
      }
      handleClose();
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving document:', error);
    }
  };

  const handleDeleteConfirmOpen = (documentId) => {
    setDeleteDocumentId(documentId);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirmClose = () => {
    setDeleteConfirmOpen(false);
    setDeleteDocumentId(null);
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/plans/${deleteDocumentId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        setDownloads(downloads.filter(doc => doc.id !== deleteDocumentId));
        setFilteredDownloads(filteredDownloads.filter(doc => doc.id !== deleteDocumentId));
        setSnackbarMessage('Documento eliminado correctamente.');
        setSnackbarOpen(true);
      } else {
        const errorData = await response.json();
        console.error('Failed to delete document:', errorData);
        setSnackbarMessage(errorData.detail || 'Error eliminando el documento.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setSnackbarMessage('Error eliminando el documento.');
      setSnackbarOpen(true);
    } finally {
      handleDeleteConfirmClose();
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const downloadPlan = async (plan) => {
    try {
      const downloadResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/plans/${plan.id}/download`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (!downloadResponse.ok) {
        const errorData = await downloadResponse.json();
        if (downloadResponse.status === 401) {
          setSnackbarMessage('Sesión expirada. Por favor, inicie sesión nuevamente.');
          setSnackbarOpen(true);
          setTimeout(() => window.location.href = '/login', 3000);
        } else if (downloadResponse.status === 403) {
          setSnackbarMessage(errorData.detail);
          setSnackbarOpen(true);
        }
        throw new Error('Network response was not ok');
      }

      const blob = await downloadResponse.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${plan.name}.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="download-history">
      <Sidebar />
      <div className={classes.content}>
        <h1>Historial de Descargas</h1>
        <div className={classes.filterContainer}>
          <div className={classes.filterWrapper}>
            <TextField
              label="Filtrar por nombre del proyecto"
              variant="outlined"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              className={classes.filterInput}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              InputProps={{
                className: classes.input,
              }}
            />
            <TextField
              label="Filtrar por fecha"
              variant="outlined"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className={classes.filterInput}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              InputProps={{
                className: classes.input,
              }}
            />
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={orderBy === 'project_name'}
                    direction={orderBy === 'project_name' ? order : 'asc'}
                    onClick={() => handleRequestSort('project_name')}
                    className={classes.tableSortLabel}
                  >
                    Nombre del Proyecto
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  Descargar Planes
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={orderBy === 'date'}
                    direction={orderBy === 'date' ? order : 'asc'}
                    onClick={() => handleRequestSort('date')}
                    className={classes.tableSortLabel}
                  >
                    Fecha
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedDownloads.map((download) => (
                <TableRow key={download.id}>
                  <TableCell component="th" scope="row">
                    {download.name}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => downloadPlan(download)} className={classes.downloadLink}>
                      Descargar Planes
                    </Button>
                  </TableCell>
                  <TableCell>{new Date(download.created_at).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleOpen(download.id)}>
                      Ver/Editar
                    </Button>
                    <Button onClick={() => handleDeleteConfirmOpen(download.id)}>
                      Eliminar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.modalContent}>
            <h2 id="simple-modal-title">Contenido del Documento</h2>
            <textarea
              value={documentContent}
              onChange={(e) => setDocumentContent(e.target.value)}
              className={classes.textarea}
            />
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              className={classes.saveButton}
              disabled={saving}
            >
              {saving ? 'Guardando...' : 'Guardar Cambios'}
            </Button>
          </div>
        </Modal> */}
        <PreviewModal
          isOpen={open}
          onRequestClose={handleClose}
          initialContent={documentContent}
          onSave={handleSave}
          generating={saving}
        />
        <Modal
          open={deleteConfirmOpen}
          onClose={handleDeleteConfirmClose}
          aria-labelledby="delete-confirmation-title"
          aria-describedby="delete-confirmation-description"
        >
          <div className={classes.modalContent}>
            <h2 id="delete-confirmation-title">Confirmación de Eliminación</h2>
            <p id="delete-confirmation-description">¿Estás seguro de que deseas eliminar este documento? Esta acción no se puede deshacer.</p>
            <div className={classes.buttonGroup}>
              <Button
                onClick={handleDelete}
                variant="contained"
                color="secondary"
              >
                Eliminar
              </Button>
              <Button
                onClick={handleDeleteConfirmClose}
                variant="contained"
                color="primary"
                className={classes.buttonMargin}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </Modal>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="success">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default DownloadHistory;