import React, { useEffect, useState } from 'react';

import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Sidebar from '../components/UI/Sidebar';
import './PricingBilling.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PricingBilling = () => {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const stripe = useStripe();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const token = localStorage.getItem('token');
  
  useEffect(() => {
    // Verificar si hay un parámetro de éxito en la URL
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get('success');
    if (success === 'true') {
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 5000); // Ocultar después de 5 segundos
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const plansResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscriptions/products`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!plansResponse.ok) {
          throw new Error('Error al obtener los planes');
        }

        const plansData = await plansResponse.json();
        setPlans(plansData);

        const subscriptionResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscriptions/active?include_product=True`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!subscriptionResponse.ok) {
          throw new Error('Error al obtener la suscripción');
        }

        const subscriptionData = await subscriptionResponse.json();
        setSubscription(subscriptionData);

        if (!subscriptionData && plansData.length > 0) {
          const expertPlans = plansData.filter(p => p.name === 'Expert');
          setSelectedPlan(expertPlans[0]);
        }

      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [token]);

  const selectPlan = (plan) => {
      setShowWarning(true);
  };

  const handleExpertPlanChange = (e) => {
    const planId = e.target.value;
    const selectedExpertPlan = plans.find(plan => plan.id === planId);
    if (selectedExpertPlan) {
      setSelectedPlan(selectedExpertPlan);
    }
  };
  const createCheckoutSession = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscriptions/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ product_id: selectedPlan.id }),
      });

      if (!response.ok) {
        throw new Error('Error creando la sesión de pago');
      }

      const session = await response.json();
      if (stripe) {
        const result = await stripe.redirectToCheckout({ sessionId: session.checkout_session_id });
        if (result.error) {
          console.error('Error:', result.error.message);
        }
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const cancelSubscription = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscriptions/${subscription.id}/cancel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });

      if (!response.ok) {
        throw new Error('Error al cancelar la suscripción');
      }

      const data = await response.json();
      setSubscription(null);
      setShowWarning(false);
      alert("Tu suscripción ha sido cancelada con exito");
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  return (
    <div className="pricing-billing">
      <Sidebar />
      <div className="model-content">
        <h1>Mejora a Expert</h1>
        <p>Nuestro plan experto proporciona herramientas financieras avanzadas para la modelación de negocios</p>

        {showSuccessMessage && (
          <div className="success-message">
            ¡Felicidades! Tu suscripción se ha actualizado con éxito.
          </div>
        )}
        {subscription ? (
          <>
            <div className="subscription-info">
              <h2>Suscripción Actual</h2>
              <div className="subscription-details">
                <p><strong>Estado:</strong> {subscription.status}</p>
                <p><strong>Próxima Fecha de Facturación:</strong> {new Date(subscription.current_period_end).toLocaleDateString()}</p>
                <p><strong>Plan:</strong> {subscription.product.name}</p>
                <p><strong>Monto:</strong> ${subscription.product.price} USD</p>
                <p><strong>Intervalo:</strong> {subscription.product.recurrency}</p>
              </div>
              <div className="plan free" onClick={() => selectPlan('free', 'price_id_free')}>
                <h2>Gratis</h2>
                <button onClick={() => selectPlan('free', 'price_id_free')}>Cambiar al Plan Gratis</button>
              </div>
            </div>
            {showWarning && (
              <div className="warning">
                <p>Perderás tu suscripción actual si cambias al plan gratuito. ¿Estás seguro?</p>
                <div className="downgrade-buttons">
                  <button className="cancel-subscription" onClick={cancelSubscription}>Sí, cancelar mi suscripción</button>
                  <button className="keep-subscription" onClick={() => setShowWarning(false)}>No, mantener mi suscripción</button>
                </div>
              </div>
            )}
          </>
        ) :
          (
            <div className="plans">
              {plans.map((plan, index) => {
                if (plan.name === 'Expert' && index === plans.findIndex(p => p.name === 'Expert')) {
                  const expertPlans = plans.filter(p => p.name === 'Expert');
                  return (
                    <div key={plan.id} className={`plan ${selectedPlan === 'expert' ? 'selected' : ''}`}>
                      <h2>Experto</h2>
                      <p className="price">${selectedPlan?.price || expertPlans[0].price}</p>
                      <select
                        className="expert-plan-dropdown"
                        onChange={handleExpertPlanChange}
                        value={selectedPlan?.id || ''}
                      >
                        {expertPlans.map(expertPlan => (
                          <option key={expertPlan.id} value={expertPlan.id}>
                            {expertPlan.recurrency.charAt(0).toUpperCase() + expertPlan.recurrency.slice(1)}
                          </option>
                        ))}
                      </select>
                      <p>Para la planificación financiera avanzada</p>
                      <button onClick={() => {
                        if (selectedPlan) {
                          createCheckoutSession();
                        } else {
                          console.log('selectedPlan', selectedPlan)
                          alert('Por favor, selecciona un plan');
                        }
                      }}>Mejorar Ahora</button>
                    </div>
                  );
                } else if (plan.name !== 'Expert') {
                  return (
                    <div key={plan.id} className={`plan ${selectedPlan === plan.name.toLowerCase() ? 'selected' : ''}`}>
                      <h2>{plan.name}</h2>
                      {plan.name === 'Enterprise' ? (
                        <p>Para empresas que requieren análisis financiero detallado</p>
                      ) : (
                        <p className="price">${plan.price}</p>
                      )}
                      {plan.name === 'Free' && <p>Para principiantes que pasan a ser expertos</p>}
                      {plan.name === 'Enterprise' ? (
                        <a href="mailto:aibucllc@gmail.com" className="contact-button">Contactar</a>
                      ) : (
                        <button onClick={() => selectPlan(plan)}>
                          {plan.name === 'Free' ? 'Seleccionar Plan Gratis' : `Mejorar a ${plan.name}`}
                        </button>
                      )}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}

        {!subscription && (
          <div className="no-subscription-info">
            <h3>Beneficios de la Membresía Premium</h3>
            <ul>
              <li>Acceso a herramientas financieras avanzadas</li>
              <li>Soporte prioritario</li>
              <li>Descargas ilimitadas de planes de negocios y análisis financieros</li>
            </ul>
          </div>
        )}

        <div className="stripe-info">
          <a href="https://stripe.com/es-us" target="_blank" rel="noopener noreferrer">
            <img className="stripe-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png" alt="Logo de Stripe" />
          </a>
        </div>
      </div>
    </div>
  );
};

const PricingBillingWrapper = () => (
  <Elements stripe={stripePromise}>
    <PricingBilling />
  </Elements>
);

export default PricingBillingWrapper;
