import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle, FaEye, FaSignInAlt, FaRegUserCircle } from 'react-icons/fa';
import { RiEyeCloseFill } from 'react-icons/ri';
import { MdOutlineLock } from 'react-icons/md';
import './Login.css';
import { useAuth } from '../../AuthContext';

const Login = () => {
  const navigate = useNavigate();
  const { handleLogin } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const handleLoginClick = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const success = await handleLogin(username, password);
      if (success) {
        navigate('/home');
      } else {
        setError('Inicio de sesión fallido');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'Error de red. Por favor, intente más tarde.');
    }
  };

  return (
    <div className="login-page">
      <div className="login-form">
        <h1>Iniciar Sesión</h1>
        <form onSubmit={handleLoginClick}>
          <div className="form-group">
            <FaRegUserCircle className="input-icon" />
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              placeholder="Email"
            />
          </div>
          <div className="form-group">
            <MdOutlineLock className="input-icon" />
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Contraseña"
            />
            <button type="button" className="toggle-password" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <RiEyeCloseFill /> : <FaEye />}
            </button>
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" className="submit-btn">
            <FaSignInAlt className="btn-icon" /> Iniciar Sesión
          </button>
        </form>
        <div className="additional-options">
          <button onClick={() => navigate('/register')} className="secondary-btn">
            <FaRegUserCircle className="btn-icon" /> Registrarse
          </button>
          <button onClick={() => navigate('/forgot-password')} className="text-btn">
            ¿Olvidaste tu contraseña?
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;