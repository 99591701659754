import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import DOMPurify from 'dompurify';

const PreviewModal = ({ isOpen, onRequestClose, initialContent, onSave, generating }) => {
  const [editableContent, setEditableContent] = useState('');
  const contentEditableRef = useRef(null);
  const scrollPosition = useRef(0);
  const caretPosition = useRef(0);

  useEffect(() => {
    setEditableContent(initialContent);
  }, [initialContent]);

  useEffect(() => {
    if (contentEditableRef.current) {
      contentEditableRef.current.scrollTop = scrollPosition.current;
      setCaretToPosition(contentEditableRef.current, caretPosition.current);
    }
  }, [editableContent]);

  const getCaretCharacterOffsetWithin = (element) => {
    let caretOffset = 0;
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.startContainer, range.startOffset);
      caretOffset = preCaretRange.toString().length;
    }
    return caretOffset;
  };

  const setCaretToPosition = (element, offset) => {
    const range = document.createRange();
    const selection = window.getSelection();
    let charIndex = 0;
    let nodeStack = [element];
    let node;
    let foundStart = false;
    let stop = false;

    while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType === 3) {
        const nextCharIndex = charIndex + node.length;
        if (!foundStart && offset >= charIndex && offset <= nextCharIndex) {
          range.setStart(node, offset - charIndex);
          foundStart = true;
        }
        charIndex = nextCharIndex;
      } else {
        let i = node.childNodes.length;
        while (i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }

    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleContentChange = () => {
    if (contentEditableRef.current) {
      caretPosition.current = getCaretCharacterOffsetWithin(contentEditableRef.current);
      scrollPosition.current = contentEditableRef.current.scrollTop;

      setEditableContent(contentEditableRef.current.innerHTML);

      window.requestAnimationFrame(() => {
        setCaretToPosition(contentEditableRef.current, caretPosition.current);
      });
    }
  };

  const sanitizeContent = (content) => {
    return DOMPurify.sanitize(content, { ALLOWED_TAGS: ['p', 'h1', 'h2', 'h3', 'ul', 'ol', 'li', 'strong', 'em'] });
  };

  const handleSaveClick = () => {
    onSave(editableContent);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Preview Business Plan"
      className="Modal"
      overlayClassName="Overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="preview-content">
        {generating && "Por favor espera mientras se genera el documento..."}
        <div
          ref={contentEditableRef}
          contentEditable
          dangerouslySetInnerHTML={{ __html: sanitizeContent(editableContent) }}
          onInput={handleContentChange}
          className="editable-div"
        />
      </div>
      <div className="preview-modal-buttons">
        <button className="close-preview-button" onClick={onRequestClose}>
          Cerrar
        </button>
        <button
          className={`download-button ${generating ? 'disabled' : ''}`}
          onClick={handleSaveClick}
          disabled={generating}
        >
          Guardar
        </button>
      </div>
    </Modal>
  );
};

export default PreviewModal;
