import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaPaperPlane, FaPencilAlt, FaDownload, FaRedo, FaTimes, FaComments, FaEye, FaMicrophone, FaTrash, FaStop } from 'react-icons/fa';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../UI/Sidebar';
import './ModelBusiness.css';
import PreviewModal from './PreviewModal';
import UniqueFloatingChat from './UniqueFloatingChat';
import DOMPurify from 'dompurify';

const createWelcomeMessage = (subscription) => ({
  role: 'system',
  title: "Puntos clave de tu Plan de negocios",
  content: (
    <>
      <p>
        El resumen ejecutivo es una parte esencial en un plan de negocios, y el mismo se realiza al final ya que engloba los puntos clave para el proyecto y sus inversores. Finalizado el plan, se realizará de manera automática.
        <br /><br />
        <i>Disponible en la versión Expert ya que incluye los datos que se calcularán en el análisis de riesgos y evaluación financiera.</i>
        <br /><br />
        Para hacer tu plan de negocio vamos a pasar por estos puntos:
        <br /><br />
        1. Descripción de negocio<br />
        2. Producto o servicio<br />
        3. Descripción del Sector<br />
        4. Estrategias de Comercialización<br />
        5. Gestión y Personal<br />
        6. Protección y Normativa<br />
        7. Plan de Puesta en Marcha<br />
        {subscription && (
          <>
            8. Análisis de Riesgo<br />
            9. Plan Económico Financiero<br />
          </>
        )}
      </p>
    </>
  ),
  isWelcome: true
});

const ModelBusiness = () => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [generating, setGenerating] = useState(false);
  const [chatGenerating, setChatGenerating] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [previewTextContent, setPreviewTextContent] = useState('Aquí se irá generando en tiempo real el documento.');
  const [showDownloadMessage, setShowDownloadMessage] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [aiMessages, setAiMessages] = useState([]);
  const [aiInput, setAiInput] = useState('');
  const [isMinimized] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const aiInputRef = useRef(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [sections, setSections] = useState([]);
  const [currentSection, setCurrentSection] = useState('');
  const [responses, setResponses] = useState({});
  const [questions, setQuestions] = useState({});
  const fetchedRef = useRef(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [nameModalOpen, setNameModalOpen] = useState(false);
  const [planName, setPlanName] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [transcript, setTranscript] = useState('');
  const mediaRecorderRef = useRef(null);
  const whisperRef = useRef(null);
  const [planType, setPlanType] = useState('business');
  const [subscription, setSubscription] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const recognition = useRef(null);
  const miniChatEndRef = useRef(null);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const [showFinancialMessage, setShowFinancialMessage] = useState(false);
  const [createdExecutiveSummary, setCreatedExecutiveSummary] = useState(false);
  const [isFinancialPlan, setIsFinancialPlan] = useState(false);
  const chatRef = useRef(null);
  

  
  useEffect(() => {
    if (showWelcomeMessage && planType === 'business') {
      const welcomeMessage = createWelcomeMessage(subscription);
      setMessages(prevMessages => {
        const filteredMessages = prevMessages.filter(msg => !msg.isWelcome);
        return [welcomeMessage, ...filteredMessages];
      });
    } else {
      setMessages(prevMessages => prevMessages.filter(msg => !msg.isWelcome));
    }
  }, [subscription, showWelcomeMessage, planType]);



  useEffect(() => {
    if (completed) {
      setShowDownloadMessage(true);
    }
  }, [completed]);

  useEffect(() => {
    if (!fetchedRef.current) {
      fetchQuestions("business");
      fetchedRef.current = true;
    }
  }, []);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscriptions/active`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        });

        if (!response.ok) {
          throw new Error('Error al obtener la suscripción');
        }

        const data = await response.json();
        setSubscription(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchSubscription();
  }, [token]);

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  }

  const fetchQuestions = useCallback(async (planType, planResponses) => {
    if (completed || generating) return;
    try {
      const body = planResponses ? { responses: planResponses } : {};
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/plans/new/questions/${planType}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data && Array.isArray(data)) {
        setQuestions(data);
        const sections = data.map(item => item.section);
        setSections(sections);
        setCurrentSection(sections[0]);
        setCurrentQuestionIndex(0);

        const initialSectionQuestions = data[0];
        setMessages(prevMessages => [
          ...prevMessages.filter(msg => msg.isWelcome),
          { role: 'system', content: initialSectionQuestions.section.name, isTitle: true },
          { role: 'system', content: initialSectionQuestions.section.header, isHeader: true },
          { role: 'system', content: initialSectionQuestions.questions[0], isQuestion: true }
        ]);
        if (planType === 'business') {
          setPreviewTextContent('Aquí se irá generando en tiempo real el documento.');
          setIsFinancialPlan(false);
        } else if (planType === 'financial') {
          setIsFinancialPlan(true);
        }
      } else {
        console.log('No questions received or questions object is empty');
      }
    } catch (error) {
      console.error('Error fetching new plan form:', error);
    } finally {
      setGenerating(false);
    }
  }, [completed, generating, token]);

  const addSectionTitle = (section) => {
    setMessages(prevMessages => [...prevMessages, { role: 'system', content: section, isTitle: true }]);
  };

  const addQuestionMessage = (question) => {
    setMessages(prevMessages => [...prevMessages, { role: 'system', content: question }]);
  };

  const addSectionHeader = (header) => {
    setMessages(prevMessages => [...prevMessages, { role: 'system', content: header, isHeader: true }]);
  };

  const handleNameSubmit = async () => {
    setNameModalOpen(false);
    openPreviewModal();
  };

  const generateExecutiveSummary = (updatedResponses) => {
    submitSectionResponses(updatedResponses, true, true);
    setCreatedExecutiveSummary(true);
  };

  const submitSectionResponses = async (updatedResponses, isLastSection = false, isExecutiveSummary = false) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/plans/section-preview`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          section: isExecutiveSummary ? "Resumen Ejecutivo" : currentSection.name,
          responses: updatedResponses
        }),
      });
      if (!response.ok) {
        throw new Error('Error submitting responses');
      }
      const data = await response.json();
      setGenerating(true);
      pollTaskStatus(data.task_id, updatedResponses, isLastSection, isExecutiveSummary);
    } catch (error) {
      console.error('Error submitting responses:', error);
    }
  };

  const updatePreviewTextContent = (prevContent, newContent, isExecutiveSummary) => {
    let updatedContent;
    if (prevContent === 'Aquí se irá generando en tiempo real el documento.') {
      updatedContent = newContent;
    } else {
      updatedContent = isExecutiveSummary
        ? `${newContent}\n\n${prevContent}`
        : `${prevContent}\n\n${newContent}`;
    }
    
    // Llamamos a scrollToLastTitle después de un breve delay para asegurar que el DOM se ha actualizado
    setTimeout(scrollToLastTitle, 100);
    
    return updatedContent;
  };

  const pollTaskStatus = async (taskId, updatedResponses, isLastSection, isExecutiveSummary = false) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/plans/task/${taskId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error polling task status');
      }

      const data = await response.json();
      if (data.status === 'COMPLETED') {
        setGenerating(false);

        if (isLastSection) {
          if (!isExecutiveSummary && !createdExecutiveSummary) {
            generateExecutiveSummary(updatedResponses);
          }

          if (planType === 'financial' || !subscription) {
            setCompleted(true);
          }
        }

        setPreviewTextContent(prevContent => {
          const updatedContent = updatePreviewTextContent(prevContent, data.result, isExecutiveSummary);
          return updatedContent;
        });

        scrollToBottom();

      } else if (data.status === 'FAILED') {
        console.error('Plan generation failed');
        setCompleted(true);
      } else {
        setTimeout(() => pollTaskStatus(taskId, updatedResponses, isLastSection, isExecutiveSummary), 5000);
      }
    } catch (error) {
      console.error('Error polling task status:', error);
    }
  };



  const scrollToLastTitle = () => {
    const previewContent = document.querySelector('.preview-content');
    if (previewContent) {
      const titles = previewContent.querySelectorAll('h1');
      if (titles.length > 0) {
        const lastTitle = titles[titles.length - 1];
        lastTitle.scrollIntoView({ behavior: 'auto', block: 'start' });
      }
    }
  };


 

  useEffect(() => {
    if (completed) {
      setNameModalOpen(true);
    }
  }, [completed]);

  const moveToNextQuestionInCurrentSection = (currentSectionQuestions) => {
    if (currentQuestionIndex < currentSectionQuestions.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      addQuestionMessage(currentSectionQuestions[currentQuestionIndex + 1]);
      return true;
    }
    return false;
  };



  const handleLastSection = (updatedResponses) => {
    if (planType === 'business' && subscription) {
      setPlanType('financial');
      setShowWelcomeMessage(false);
      setShowFinancialMessage(true); // Mostrar el mensaje del plan financiero
      fetchQuestions('financial', updatedResponses);
      submitSectionResponses(updatedResponses, true);
    } else if (planType === "financial") {
      setCompleted(true);
    } else {
      submitSectionResponses(updatedResponses, true);
    }
  };


  const moveToNextSection = (currentSectionIndex, updatedResponses) => {
    if (planType === "business") {
      submitSectionResponses(updatedResponses, false);
    }

    const nextSection = sections[currentSectionIndex + 1];
    setCurrentSection(nextSection);
    setCurrentQuestionIndex(0);
    addSectionTitle(nextSection.name);
    addSectionHeader(nextSection.header);

    const nextSectionQuestions = questions.find(
      section => section.section.name === nextSection.name
    ).questions;
    addQuestionMessage(nextSectionQuestions[0]);
  };

  const goToNextQuestion = useCallback((updatedResponses) => {
    const currentSectionQuestions = questions.find(
      section => section.section.name === currentSection.name
    ).questions;

    if (moveToNextQuestionInCurrentSection(currentSectionQuestions)) {
      scrollToBottom();
      return;
    }

    const currentSectionIndex = sections.indexOf(currentSection);
    const isLastSection = currentSectionIndex === sections.length - 1;

    if (isLastSection) {
      handleLastSection(updatedResponses);
    } else {
      moveToNextSection(currentSectionIndex, updatedResponses);
    }

    scrollToBottom();
  }, [currentSection, currentQuestionIndex, questions, sections, addQuestionMessage, addSectionTitle, addSectionHeader, setCurrentSection, setCurrentQuestionIndex, submitSectionResponses, planType, subscription]);

  const handleSend = useCallback(async (e) => {
    e.preventDefault();
    if (userInput.trim() === '' || isSubmitting) return;

    let inputValue = userInput;
    if (isFinancialPlan) {
      // Validar que la entrada sea un número válido
      const numericValue = parseFloat(userInput);
      if (isNaN(numericValue)) {
        setSnackbarMessage('Por favor, ingrese un número válido.');
        setSnackbarOpen(true);
        return;
      }
      inputValue = numericValue.toString(); // Asegurar que se guarde como string
    }

    setIsSubmitting(true);
    let updatedResponses;
    try {
      const currentSectionObject = questions.find(
        section => section.section.name === currentSection.name
      );
      const question = currentSectionObject.questions[currentQuestionIndex];

      setMessages(prevMessages => [
        ...prevMessages,
        { role: 'user', content: inputValue },
      ]);
      await new Promise(resolve => {
        setResponses(prevResponses => {
          updatedResponses = { ...prevResponses };
          if (!updatedResponses[currentSection.name]) {
            updatedResponses[currentSection.name] = [];
          }

          const existingQuestionIndex = updatedResponses[currentSection.name].findIndex(
            item => item.question === question
          );

          if (existingQuestionIndex !== -1) {
            updatedResponses[currentSection.name][existingQuestionIndex].answer = inputValue;
          } else {
            updatedResponses[currentSection.name].push({ question, answer: inputValue });
          }

          resolve();
          return updatedResponses;
        });
      });

      await new Promise(resolve => setTimeout(resolve, 0));
      setUserInput('');
      goToNextQuestion(updatedResponses);

    } catch (error) {
      console.error('Error handling send:', error);
      setSnackbarMessage('Error al enviar la respuesta. Por favor, intente de nuevo.');
      setSnackbarOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  }, [userInput, isSubmitting, questions, currentSection, currentQuestionIndex, responses, goToNextQuestion, setSnackbarMessage, setSnackbarOpen, isFinancialPlan]);

  const downloadPlan = async (plan) => {
    try {
      const downloadResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/plans/${plan.id}/download`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (!downloadResponse.ok) {
        const errorData = await downloadResponse.json();
        if (downloadResponse.status === 401) {
          navigate('/login');
        } else if (downloadResponse.status === 403) {
          setSnackbarMessage(errorData.detail);
          setSnackbarOpen(true);
        }
        throw new Error('Network response was not ok');
      }

      const blob = await downloadResponse.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${plan.name}.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const generateBusinessPlan = async () => {
    setGenerating(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/plans/new`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: planName, content: previewTextContent, responses: responses }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 401) {
          navigate('/login');
        } else if (response.status === 403) {
          setSnackbarMessage(errorData.detail);
          setSnackbarOpen(true);
        }
        throw new Error('Network response was not ok');
      }

      const plan = await response.json();
      await downloadPlan(plan);
      setSnackbarMessage('Plan de negocios guardado y descargado exitosamente.');
      setSnackbarOpen(true);
      navigate('/downloads');

    } catch (error) {
      console.error('There was an error downloading the business plan!', error);
      setSnackbarMessage('Error al descargar el plan de negocios. Por favor, intente nuevamente.');
      setSnackbarOpen(true);
    } finally {
      setGenerating(false);
      setPreviewModalOpen(false);
    }
  };

  const startNewBusinessPlan = async () => {
    const confirmReset = window.confirm("¿Estás seguro de que quieres reiniciar el formulario? ¡Perderás todos los datos ingresados!");
    if (!confirmReset) return;
    setMessages([]);
    setGenerating(false);
    setCompleted(false);
    setShowDownloadMessage(false);
    setPreviewTextContent('Aquí se irá generando en tiempo real el documento.');
    fetchQuestions("business");
  };

  const handleAiSend = async (e) => {
    e.preventDefault();
    if (aiInput.trim() === '') return;

    const newMessage = { role: 'user', content: aiInput };
    setAiMessages(prevMessages => [...prevMessages, newMessage]);
    setAiInput('');
    askAI(aiInput);
    aiInputRef.current.focus();
    if (aiInputRef.current) {
      aiInputRef.current.focus();
    }
    scrollToBottom();
  };


  const [aiResponse, setAiResponse] = useState(null);
  const cleanResponse = (response) => {
    return response.replace(/^Sugerencia\s*-?\s*Answer:\s*/i, '').trim();
  };
  const handleAiSendWithContext = async (e) => {
    e.preventDefault();
  
    const currentSectionObject = questions.find(
      section => section.section.name === currentSection.name
    );
    const currentQuestion = currentSectionObject.questions[currentQuestionIndex];
    
    // Mensaje detallado para el asistente AI (no visible para el usuario)
    const detailedPrompt = `Necesito ayuda para responder la siguiente pregunta para la creación de un plan de negocios: \n\n'${currentQuestion}'\n\nPodrías completar este punto por mí? teniendo en cuenta todo el contexto anterior, responde directamente lo que te pido. siendo lo mas profesional posible, trata de que la respuesta este bien fundamentada y sea perfectamente compatible con el plan de negocios, no pongas cosas asi "Claro, basándonos en la información que me has brindado hasta ahora, puedo completar esa pregunta por ti:- Answer, no de des ninguna intro y responde la pregunta ya que asi como la respondas es como se enviara, como te dije anteriormente, debes ser lo mas profesional posible y extenderte en caso de que la respuesta lo requiera"`;
  
    // Mensaje simplificado para mostrar en el mini chat
    const simplifiedMessage = `Solicitando ayuda para: "${currentQuestion}"`;
  
    // Agregar el mensaje simplificado al mini chat
    setAiMessages(prevMessages => [...prevMessages, { role: 'user', content: simplifiedMessage }]);
    
    setGenerating(true);
    try {
      let response = await askAI(detailedPrompt);
      response = cleanResponse(response);
      
      // Añadir la respuesta al mini chat
      setAiMessages(prevMessages => [...prevMessages, { role: 'ai', content: response }]);
      
      // Colocar la respuesta en el campo de entrada principal
      setUserInput(response);
      
      scrollToBottom();
    } catch (error) {
      console.error('Error al obtener la respuesta de la IA:', error);
      setSnackbarMessage('Error al obtener la ayuda del asistente. Por favor, intente de nuevo.');
      setSnackbarOpen(true);
    } finally {
      setGenerating(false);
    }
    if (aiInputRef.current) {
      aiInputRef.current.focus();
    }
    scrollToBottom();
  };
  const askAI = async (question) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/assistant/message`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message: question, context: aiMessages, qa_pairs: responses }),
      });
      const responseData = await response.json();
      return responseData.message;
    } catch (error) {
      console.error('There was an error asking the AI!', error);
      throw error;
    }
  };

  const clearChat = () => {
    setAiMessages([]);
    setAiInput('');
  };

  const handleUserInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend(e);
    }
  };

  const openPreviewModal = async () => {
    setPreviewModalOpen(true);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };
  const scrollMiniChatToBottom = () => {
    if (miniChatEndRef.current) {
      miniChatEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  // Efecto para manejar el scroll automático en el chat principal
useEffect(() => {
  scrollToBottom();
}, [messages, isChatOpen]);

// Efecto para manejar el scroll automático en el mini chat
useEffect(() => {
  scrollMiniChatToBottom();
}, [aiMessages, isChatOpen]);



  const toggleListening = () => {
    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };
  const openAssistantChat = () => {
    if (chatRef.current) {
      chatRef.current.openChat();
    }
  };

  const startListening = () => {
    recognition.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.current.continuous = true;
    recognition.current.interimResults = true;
    recognition.current.lang = 'es-ES';

    recognition.current.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map(result => result[0])
        .map(result => result.transcript)
        .join('');

      setUserInput(transcript);
    };

    recognition.current.start();
    setIsListening(true);
  };

  const stopListening = () => {
    if (recognition.current) {
      recognition.current.stop();
      setIsListening(false);
    }
  };

  const sendAudioToWhisper = async (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audio.wav');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transcribe-audio`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error transcribing audio');
      }

      const data = await response.json();
      if (data.transcription) {
        setUserInput(data.transcription);
        handleSend({ preventDefault: () => { } });
      }
    } catch (error) {
      console.error('Error uploading audio', error);
    }
  };

  const closePreviewModal = () => {
    setPreviewModalOpen(false);
    setShowDownloadMessage(false);
    setGenerating(false);
    setCompleted(false);
    setMessages([]);
    setPreviewTextContent('Aquí se irá generando en tiempo real el documento.');
    fetchQuestions("business");
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, aiMessages, isChatOpen]);
  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
    scrollToBottom(); // Asegura que el chat principal haga scroll cuando se abre o cierra el mini chat
  };
  

  const handleAcceptAiResponse = (response) => {
    setUserInput(response);
    setAiResponse(null);
    handleSend({ preventDefault: () => {} });
  };

  return (
    <div className="container">
      <Sidebar onExpand={() => setIsSidebarExpanded(true)} onCollapse={() => setIsSidebarExpanded(false)} />
      <div className={`main-content ${isSidebarExpanded ? 'sidebar-expanded' : ''}`}>
        {/* ... */}
      </div>
       <div className="content">
        <div className="real-time-preview">
          <h3>Vista Previa</h3>
          <div
            className="preview-content non-selectable"
            dangerouslySetInnerHTML={createMarkup(previewTextContent)}
          />
        </div>

        <div className="chat-container">
          <div className="ai-chat-container">
            <div className="ai-chat-header">
              <h2>{planType === "business" ? "Plan de Negocios" : "Plan Financiero"}</h2>
            </div>
            {showFinancialMessage && (
              <div className="financial-message">
                Nota: En esta sección solo se aceptan números y puntos para decimales.
              </div>
            )}
            <div className="ai-chat-body">
              {messages
                .filter(message => message.content)
                .map((message, index) => (
                  <div key={index} className={`ai-message ${message.role === 'user' ? 'user-message' : 'system-message'}`}>
                    {message.isTitle ? (
                      <h3 className="section-title"><strong>{message.content}</strong></h3>
                    ) : message.isHeader ? (
                      <h4 className="section-header">{message.content}</h4>
                    ) : message.isQuestion ? (
                      <p className="question"><strong>{message.content}</strong></p>
                    ) : (
                      <p>{message.content}</p>
                    )}
                  </div>
                ))}
              <div ref={messagesEndRef} />
            </div>
            <div className="ai-chat-buttons">
              <button 
                className="ai-button" 
                onClick={handleAiSendWithContext}
                disabled={generating}
              >
                {generating ? 'Generando respuesta...' : 'No estoy seguro, ¿podrías completar este punto por mí?'}
              </button>
              <button className="ai-button" onClick={openAssistantChat}>
                Abrir asistente para responder de forma eficiente.
              </button>
            </div>
            <form className="ai-chat-input-container" onSubmit={handleSend}>
              <input
                className="ai-chat-input"
                placeholder={isFinancialPlan ? "Ingrese un número" : "Escribe tus respuestas aquí"}
                value={userInput}
                onChange={(e) => {
                  if (isFinancialPlan) {
                    const value = e.target.value;
                    if (/^\d*\.?\d*$/.test(value)) {
                      setUserInput(value);
                    }
                  } else {
                    setUserInput(e.target.value);
                  }
                }}
                ref={inputRef}
                disabled={generating}
                type="text"
                inputMode={isFinancialPlan ? "decimal" : "text"}
              />
              <button className="ai-chat-send-button" type="submit" disabled={generating}>
                <FaPaperPlane />
              </button>
              <button
                type="button"
                className={`ai-chat-audio-button ${isListening ? 'listening' : ''}`}
                onClick={toggleListening}
                disabled={generating}
              >
                {isListening ? <FaStop /> : <FaMicrophone />}
              </button>
            </form>
            {generating && (
              <div className="loading-container">
                <div className="loader"></div>
              </div>
            )}
            <div className="button-container">
              <button className="new-plan-button icon-button" onClick={startNewBusinessPlan}>
                <FaRedo />
              </button>
              <button className="clear-chat-button icon-button" onClick={clearChat}>
                <FaTrash />
              </button>
            </div>
          </div>

          <Modal
            isOpen={nameModalOpen}
            onRequestClose={() => setNameModalOpen(false)}
            contentLabel="Nombre del Plan de Negocios"
            className="NameModal"
            overlayClassName="Overlay"
          >
            <div className="name-modal-content">
              <h2 className="name-modal-title">¡Felicidades! Tu plan de negocios se está creando!</h2>
              <p className="name-modal-subtitle">Dale un nombre memorable a tu proyecto:</p>
              <div className="name-input-container">
                <input
                  type="text"
                  value={planName}
                  onChange={(e) => setPlanName(e.target.value)}
                  className="plan-name-input"
                  placeholder="Ej: MiEmpresaInnovadora 2024"
                />
                <span className="input-icon">
                  <FaPencilAlt />
                </span>
              </div>
              <div className="name-modal-buttons">
                <button className="cancel-button" onClick={() => setNameModalOpen(false)}>
                  Cancelar
                </button>
                <button
                  className="submit-name-button"
                  onClick={handleNameSubmit}
                  disabled={!planName.trim()}
                >
                  Guardar y Continuar
                </button>
              </div>
              <p className="name-modal-tip">Tip: Un buen nombre ayudará a identificar fácilmente tu plan en el futuro.</p>
            </div>
          </Modal>
        </div>
      </div>
      {showDownloadMessage && (
       <div className="floating-message show">Para descargar debe guardar el documento y presionar el botón de descarga.</div>
      )}
      <PreviewModal
        isOpen={previewModalOpen}
        onRequestClose={closePreviewModal}
        initialContent={previewTextContent}
        onSave={generateBusinessPlan}
        generating={generating}
      />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <UniqueFloatingChat ref={chatRef} />
    </div>
  );
};

export default ModelBusiness;