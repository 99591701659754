import React, { useState } from 'react';
import { FaEnvelope, FaKey, FaLock } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleSendCode = async () => {
    setError('');
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
        setStep(2);
      } else {
        setError(data.detail);
      }
    } catch (error) {
      setError('Error en la red. Inténtelo nuevamente más tarde.');
    }
  };

  const handleResetPassword = async () => {
    setError('');
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ reset_code: resetCode, new_password: newPassword })
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
        setStep(3);
      } else {
        setError(data.detail);
      }
    } catch (error) {
      setError('Error en la red. Inténtelo nuevamente más tarde.');
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-form">
        {step === 1 && (
          <>
            <h1>Restablecer Contraseña</h1>
            <div className="input-group">
              <FaEnvelope className="input-icon" />
              <input
                type="email"
                placeholder="Correo Electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {error && <p className="error-message">{error}</p>}
            {message && <p className="success-message">{message}</p>}
            <button className="submit-btn" onClick={handleSendCode}>Enviar Código</button>
          </>
        )}
        {step === 2 && (
          <>
            <h1>Nueva Contraseña</h1>
            <div className="input-group">
              <FaKey className="input-icon" />
              <input
                type="text"
                placeholder="Código de Restablecimiento"
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
              />

            </div>
            <div className="input-group">
              <FaLock className="input-icon" />
              <input
                type="password"
                placeholder="Nueva Contraseña"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>            
            {error && <p className="error-message">{error}</p>}
            {message && <p className="success-message">{message}</p>}
            <button className="submit-btn" onClick={handleResetPassword}>Verificar Código</button>
          </>
        )}
        {step === 3 && (
          <>
            <h1>Contraseña Restablecida</h1>
            <p className="success-message">{message}</p>
            <button className="submit-btn" onClick={() => navigate('/login')}>Iniciar Sesión</button>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;