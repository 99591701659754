import React from 'react';

const Cancel = () => (
  <div>
    <h1>Payment Cancelled</h1>
    <p>Your payment was cancelled. Please try again.</p>
  </div>
);

export default Cancel;
