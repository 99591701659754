import React, { createContext, useContext, useState, useCallback } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [clientReferenceId, setClientReferenceId] = useState(localStorage.getItem('client_reference_id'));
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  const handleLogin = useCallback(async (username, password) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/login/access-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({ username, password })
      });
  
      const data = await response.json();
      if (data.success) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('client_reference_id', data.client_reference_id);
        setToken(data.token);
        setClientReferenceId(data.client_reference_id);
        setIsLoggedIn(true);
        return true;
      } else {
        throw new Error(data.detail || 'Credenciales incorrectas');
      }
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }, []);
  


  const handleRegister = async (name, email, username, password) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, email, username, password })
      });
  
      const data = await response.json();
      if (data.success) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('client_reference_id', data.id);
        setToken(data.token);
        setClientReferenceId(data.id);
        setIsLoggedIn(true);
        return true;
      } else {
        throw new Error(data.detail || 'Error en el registro');
      }
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('client_reference_id');
    setToken(null);
    setClientReferenceId(null);
    setIsLoggedIn(false);
  }, []);

  const value = {
    token,
    clientReferenceId,
    isLoggedIn,
    handleLogin,
    handleLogout,
    handleRegister
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};