import React from 'react';

const AdComponent = () => {
  return (
    <>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5018604011090932"
        crossorigin="anonymous"></script>
      <ins className="adsbygoogle"
           style={{ display: 'block' }}
           data-ad-client="ca-pub-5018604011090932"
           data-ad-slot="3277784199"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
      <script>
        {`(adsbygoogle = window.adsbygoogle || []).push({});`}
      </script>
    </>
  );
};

export default AdComponent;
