import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaEye, FaRegUser, FaRegEnvelope, FaUserPlus } from 'react-icons/fa';
import { RiEyeCloseFill } from 'react-icons/ri';
import { MdOutlineLock } from 'react-icons/md';
import zxcvbn from 'zxcvbn';
import { useAuth } from '../../AuthContext';
import './Register.css';

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const { handleRegister } = useAuth();
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'password') {
      const result = zxcvbn(value);
      setPasswordStrength(result.score);
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      setError('Debes aceptar los términos y condiciones para registrarte');
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }

    try {
      await handleRegister(formData.name, formData.email, formData.username, formData.password);
      navigate('/home');
    } catch (error) {
      setError(error.message || 'Error registrando el usuario');
    }
  };

  const getPasswordStrengthBar = (score) => {
    const strength = ['Muy débil', 'Débil', 'Aceptable', 'Fuerte', 'Muy fuerte'];
    return (
      <div className="password-strength-bar">
        <div className={`strength-level level-${score}`}></div>
        <p className="strength-text">{strength[score]}</p>
      </div>
    );
  };
  
  const TermsModal = () => (
    <div className="modal">
      <div className="modal-content">
        <h2>Términos y Condiciones</h2>
        <div className="modal-body">
          <p><p>Términos y Condiciones de contratación de AiBUC<br></br>

Preliminar: a los efectos de lo previsto en los presentes Términos y Condiciones, las siguientes expresiones tendrán el significado que a continuación se detalla.<br></br>

AiBUC es una herramienta dirigida a personas emprendedoras, profesionales y empresas que buscan crear un plan de negocios para su propio uso o para terceros, con acceso a diferentes funcionalidades y/o servicios en función del Plan de contratado y prestados a través del sitio web https://app.AiBUC.com...<br></br>

AIBUC es una empresa titular de AiBUC LLC y cuyos datos completos se hacen constar más adelante.<br></br>

Usuario team: particular o empresa (Contador, administrador de empresas, consultora, etc.) que gestiona planes de negocio haciendo uso de AiBUC, con un propósito relacionado con su actividad comercial, empresarial, oficio o profesión.<br></br>

1. Identificación de las partes<br></br>
La prestadora de los servicios de AiBUC es la empresa Aibuc LLC, con domicilio 5118 N 56TH STREET STE 111, TAMPA, 33610, HILLSBOROUGH, FLORIDA. En adelante, AIBUC.<br></br>

Puedes contactar con AIBUC de forma directa y efectiva en la dirección de correo electrónico: aibucllc@gmail.com<br></br>

Si contratas los servicios de AiBUC para teams, tendrás la condición de usuario team en el cual podrás ser Administrador/a de las Marcas que gestiones a través de AiBUC (en adelante, Administrador/a) y con la aceptación de los presentes Términos y Condiciones, declaras haber leído y comprendido su contenido, ser mayor de edad, con capacidad para obligarte conforme a lo establecido en estos, en tu propio nombre y derecho o en nombre y representación de cualquier persona física o jurídica legalmente constituida con voluntad de contratar AiBUC con un propósito relacionado con tu actividad comercial, de estudio, empresarial, oficio o profesión.<br></br>

Ciertos aspectos del servicio puedan verse sometidos a determinadas condiciones particulares, que, según los casos, sustituirán, completarán y/o modificarán los presentes términos y condiciones. En caso de contradicción, prevalecerán esas condiciones particulares sobre los presentes.<br></br>

2. Objeto<br></br>
Los servicios de AiBUC se ofertan con la fórmula freemium, consistente en ofrecer un servicio básico gratuito, mientras que otros servicios más avanzados o especiales están sujetos al pago de una contraprestación.<br></br>

El plan gratuito y los distintos planes de pago proporcionará acceso a los servicios que se indican en https://AiBUC.com/expert/, siempre condicionado a los que se incluyen en el Plan que hayas contratado y que se detallan a continuación.<br></br>

2.1. Servicio Expert:<br></br>
Incluye el plan completa, con análisis de riesgo y financiero, sumándose dashboard con toda la información necesaria para llevar a cabo un plan eficiente y eficaz.<br></br>
2.2. Servicio team:<br></br>

El servicio de Team de AiBUC permite a el/la Administrador/a, hacer uso de las funcionalidades de la versión Expert, adicionando la posibilidad de sumar usuarios a la interacción de cada proyecto.<br></br>

PDF’s: funcionalidad disponible para descargar planes en formato PDF.<br></br>

Vista en tiempo real: permite previsualizar el contenido del plan de negocios en proceso.<br></br>

Asistente IA: soporte para la creación de tu plan de negocios con Inteligencia Artificial (IA) limitado a un numero de pasos, dependiendo del Plan contratado.<br></br>

A continuación, se detallan las limitaciones de los servicios en función del Plan contratado:<br></br>

Planes en página: utilización de plantillas para cada plan.<br></br>

Miembros de equipo: disponible exclusivamente para el Plan Enterprise teams y consiste en la posibilidad de el/la Administrador/a de dar permisos de acceso a usuarios en AiBUC:<br></br>

“Solo Lectura”: para ver métricas y generar informes.<br></br>
“Manejo compartido”: para modificar planes en conjunto<br></br>

Solo el/la Administrador/a que haya contratado el Plan de AiBUC Enterprise teams tendrá acceso a los ajustes de su cuenta en AiBUC.<br></br>

3. Procedimiento de contratación de los servicios de AiBUC<br></br>
Actualmente el procedimiento de contratación podrá ser realizado en español, muy pronto en inglés, y en un futuro francés y portugués.<br></br>

AIBUC no conservará una copia de las presentes condiciones vinculada a la contratación, por lo que se recomienda la conservación de una copia de éstas.<br></br>

AIBUC no dispone de medios técnicos para identificar y corregir errores en la introducción de tus datos, a salvo de detectar si algún campo que resulta necesario, para tramitar la solicitud, está pendiente de completar.<br></br>

Para poder hacer uso de los servicios de AiBUC es requisito indispensable proceder al registro como Administrador/a.<br></br>

3.1. Registro como Administrador/a:<br></br>

Para proceder al registro como Administrador/a, debes pulsar sobre el botón “REGISTRATE” y proporcionar tus datos a través de cualquiera de las siguientes opciones:<br></br>

a) Si te registras con tu correo electrónico:<br></br>

Te pediremos tu correo electrónico y una contraseña que se almacenará cifrada y que serán tus claves de acceso a AiBUC.<br></br>

Pondremos a tu disposición información relativa al tratamiento de los datos personales, que se realizará conforme a lo previsto en la Política de Privacidad, así como los Términos Legales de AiBUC, que deberás leer y, en caso de conformidad, aceptar con el marcado de la casilla dispuesta a tal fin.<br></br>

Con la realización del registro de forma satisfactoria, podrás acceder a los servicios de AiBUC que no sean de pago.<br></br>

Para la contratación de servicios de pago, te pediremos tu nombre y apellidos, así como los datos relativos al medio de pago empleado.<br></br>

3.1.1. Veracidad de los datos proporcionados:<br></br>

Como usuario de AiBUC que has solicitado tu registro, eres el único responsable de que los datos facilitados estén actualizados, que sean completos, exactos y veraces. AIBUC declina cualquier responsabilidad por el incumplimiento de tales requisitos con ocasión de tu registro.<br></br>

Si detectas algún error en los datos facilitados, deberás proceder a su subsanación, lo antes posible, a través de tu cuenta de usuario o poniéndote en contacto con AIBUC en el indicado correo electrónico.<br></br>

3.1.2. Claves de acceso del usuario:<br></br>

Las claves de acceso (e-mail y contraseña) tienen carácter confidencial. Por lo tanto, debes ser diligente en su uso y conservación, de forma que no sea posible su utilización por terceros no autorizados. En consecuencia, te obligas a comunicar a AIBUC de forma inmediata tanto el extravío de tus claves de acceso como cualquier peligro o menoscabo en su confidencialidad. En caso contrario, deberás asumir la responsabilidad por cualquier daño o perjuicio causado mediante accesos verificados a través de tus claves de acceso.<br></br>

En el caso de que no recuerdes tu contraseña, podrás introducir tu correo electrónico para crear una contraseña nueva.<br></br>
3.1.3. Prohibición de disponer de más de una cuenta de usuario:<br></br>

Solo se permitirá la creación de una única cuenta de Administrador/a en AiBUC por cada persona física o jurídica. AIBUC se reserva el derecho a cancelar las cuentas que incumplan con lo anteriormente expuesto.<br></br>

3.2. Acceso a los servicios de pago:<br></br>
3.2.1. Contratación de un Plan de pago:<br></br>

Para contratar un plan de pago (Expert o Enterprise), una vez verificado tu registro como Administrador/a, deberás seguir los siguientes pasos:<br></br>

En tu cuenta de Administrador/a, deberás pulsar sobre el botón “Mejorar a …” para acceder al listado de Planes de pago disponibles:<br></br>

a) seleccionar el Plan (Expert o Enterprise) que más se ajuste a tus necesidades<br></br>

b) seleccionar el ciclo de facturación del servicio (diario,mensual, semestral o anual) que será el período por el que contratas el servicio.<br></br>

c) proporcionar los datos de facturación (nombre, denominación social, dirección completa, país, etc)<br></br>

d) seleccionar la forma de pago (tarjeta de crédito/débito o PayPal). Los medios de pago estarán sujetos a comprobaciones y autorizaciones por parte de la entidad emisora de estos, pero si dicha entidad no autorizase el pago no se activará la contratación. Garantizas que tienes plena autorización para el uso del medio de pago de que se trate durante el proceso contratación.<br></br>

e) si dispones de un código descuento con motivo de cualquier promoción, podrás introducirlo (los códigos descuento no son acumulables)<br></br>

f) leer las condiciones de contratación y en caso de estar conforme, marcar la casilla establecida a tal fin<br></br>

g) pulsar sobre el botón de “Mejorar plan”.<br></br>

Completada de forma satisfactoria la contratación del Plan de pago correspondiente, tendrás acceso a los servicios asociados al mismo.<br></br>

3.3. Precios de los servicios de AiBUC:<br></br>

3.3.1. Planes de AiBUC:<br></br>

El precio (mensual o anual) de los Planes de AiBUC vigentes en el momento de la contratación son los publicados en USD en https://AiBUC.com/es/premium/ que no incluyen los impuestos legalmente aplicables que, en su caso, correspondan.<br></br>

Los precios podrán ser modificados al alza o a la baja, en función de múltiples causas, no obstante, cualquier modificación de los precios surtirá efecto a partir de la fecha de contratación o renovación del servicio posterior a la modificación de estos.<br></br>

En caso de modificación del precio al alza, te comunicaremos de forma previa a la renovación automática del ciclo de contratación correspondiente (mensual o anual) y con una antelación de al menos 15 días a la fecha de renovación, el incremento del precio y se procederá a la renovación automática por el mismo plazo solo en el caso de que no te opongas a ella, una vez comunicado el cambio en el precio.<br></br>

El cargo del importe del precio correspondiente (mensual o anual) se realizará en el medio de pago seleccionado por el usuario en el proceso de contratación, siendo la primera en el momento de la contratación y las siguientes el mismo día de los meses/años sucesivos, según corresponda.<br></br>

3.4. Cambio de Plan de pago:<br></br>

Puedes cambiar de Plan conforme a lo previsto en el presente apartado:<br></br>

Si cambias de un plan gratuito (free) a cualquier plan de pago, el ciclo de facturación empezará a computarse desde la fecha en la que realices la contratación del Plan de pago correspondiente.<br></br>

Si quieres cambiar de un Plan de pago a otro superior, tendrás que pagar en el momento de la contratación del Plan superior el precio del ciclo de facturación correspondiente, menos la parte proporcional del tiempo no disfrutado del Plan inferior.<br></br>

No se realizan reembolsos de ciclos de facturación en curso, ya sea mensual o anual. Por ese motivo los ciclos de facturación semestrales/anuales son más económicos, porque contratas AiBUC con una permanencia de un año.<br></br>

3.5. Cuenta de Administrador/a en AiBUC:<br></br>

A través de tu cuenta de Administrador/a en AiBUC, tendrás acceso a los servicios del Plan contratado, y además, podrás gestionar tu cuenta:<br></br>

-Identificación: podrás modificar o actualizar tus datos personales y tus credenciales de acceso.<br></br>

– Ajustes: podrás seleccionar el idioma disponible de AiBUC, si deseas o no recibir un resumen mensual de tu actividad en AiBUC en tu correo electrónico y el correo electrónico en el que deseas recibir ese resumen mensual.<br></br>

– Suscripción: cambiar de Plan en los términos antes indicados, pudiendo modificar el medio de pago con el que realizarás el pago del precio del servicio.<br></br>

– Facturas: podrás introducir los datos fiscales correspondientes para la emisión de las facturas correspondientes.<br></br>

–AiBUC se reserva el derecho a conservar tus datos conforme a lo previsto en la Política de Privacidad durante el plazo por el cuál pudieran derivarse responsabilidades por los servicios prestados.<br></br>

3.6. Duración y terminación del servicio:<br></br>

Podrás mantener tu cuenta de Administrador/a en AiBUC con carácter indefinido para el plan Free (gratuito) de acuerdo a lo previsto en los presentes términos y condiciones.<br></br>

Para el resto de planes de pago de AiBUC (Expert y Enterprise), tendrás acceso a sus servicios siempre y cuando te encuentres al corriente en el pago del precio y durante el plazo contratado (mensual o anual). El contrato se prorrogará automáticamente por idéntico plazo al contratado inicialmente.<br></br>
También puede producirse la terminación anticipada de la contratación del servicio si concurre cualquiera de las causas previstas en la legislación aplicable o en los presentes Términos y Condiciones.<br></br>
AIBUC podrá retirar o suspender en cualquier momento y sin necesidad de previo aviso la prestación de los servicios en el caso de incumplimiento de cualquiera de las obligaciones asumidas en virtud de las presentes condiciones de contratación, con expresa reserva del derecho a reclamar, en su caso, los daños y perjuicios que se pudieran irrogar por razón de tal incumplimiento.<br></br>

3.7. Facturación:<br></br>

AIBUC emitirá la correspondiente factura detallando el Impuesto sobre el Valor Añadido (IVA) y practicando las retenciones fiscales que, en su caso, correspondan según el tipo aplicable en cada momento, siendo necesario a tal fin que proporciones tus datos completos de facturación.<br></br>

4. Denegación de acceso al servicio<br></br>
AIBUC podrá denegar el acceso a AiBUC en cualquiera de los siguientes supuestos:<br></br>

a) Compruebe que los datos facilitados son falsos, incompletos y/o inexactos.<br></br>

b) La actividad desarrollada por ti (o por terceros designados por ti) no se ajuste a los presentes Términos y Condiciones y/o se constate que ponga en riesgo el funcionamiento correcto del servicio de otros usuarios.<br></br>

c) exista pendiente de pago algún importe con AIBUC.<br></br>

La denegación de acceso a los servicios se comunicará por correo electrónico a la dirección facilitada por ti, o a través de la cuenta de Administrador/a en AiBUC o de mensajes en la propia plataforma.<br></br>

5. Promociones<br></br>
AIBUC tendrá la facultad de establecer en cualquier momento campañas promocionales de sus servicios con determinadas ventajas para los usuarios, que permanecerán vigentes durante el tiempo que aparezcan publicadas en el Sitio Web o durante el tiempo establecido en la promoción de que se trate. Las promociones no serán acumulables.<br></br>

6. Otras condiciones del servicio<br></br>
6.1. Licencia de uso AiBUC:<br></br>

Al contratar AiBUC, AIBUC te concede una licencia no exclusiva, temporal y para el territorio en el que estás establecido/a, del de AiBUC con acceso a los servicios que correspondan en función del plan contratado. Dicha licencia de uso en ningún caso supondrá la venta del, ni de ninguno de los derechos que AIBUC ostenta sobre el mismo, por lo que no podrás revenderlo, transferirlo a terceros, ni arrendar alquilar o prestar los servicios contratados, salvo en lo que prevea de forma expresa cualquiera de los Planes.<br></br>
Queda absolutamente prohibido la modificación o cualquier tipo de manipulación de la herramienta.<br></br>
6.2. Uso inadecuado de AiBUC:<br></br>
Queda terminantemente prohibida la utilización de AiBUC para cualquier finalidad diferente a la que se destinan sus funcionalidades.<br></br>
El/la Administrador/a será responsable de cualquier uso inadecuado que realice, así como del uso inadecuado de cualquier tercero al que de acceso a su cuenta.<br></br>
6.3. Participación del usuario:<br></br>

En el caso de que a través de AiBUC se permita tu participación mediante la publicación de comentarios o la generación de contenidos, queda absolutamente prohibido difundir contenidos o propaganda de carácter ilícito, racista, xenófobo, de apología del terrorismo, atentatorio contra los derechos humanos, o que inciten al odio, vulneren la dignidad de la persona, o sean de carácter injurioso, calumnioso o contra el orden o la seguridad pública.<br></br>

AIBUC se reserva el derecho de retirar todos aquellos comentarios y/o contenidos, que incumplan lo anteriormente expuesto o que no resultaran adecuados para su publicación.<br></br>

En cualquier caso, AIBUC no será responsable de las opiniones vertidas o por los contenidos publicados por los usuarios a través de los foros, chats, blog u otras herramientas de participación que puedan crearse, salvo en lo expresamente previsto por la normativa de aplicación.<br></br>

6.4. Prohibición de técnicas de screen scraping, web scraping o similares:<br></br>
Se prohíbe de forma expresa la utilización de técnicas de screen scraping, web scraping o cualquier otra técnica de programación o de cualquier otra índole que permita extraer información o datos alojados, almacenados o accesible en AiBUC sin autorización expresa y por escrito de AIBUC.<br></br>

7.N/A<br></br>

8. Disponibilidad de los servicios<br></br>
8.1. AIBUC realizará los mejores esfuerzos con el fin de garantizarte el acceso a AiBUC 24 horas al día, los 7 días de la semana, sin otra interrupción diferente a la requerida para labores de prevención o de mantenimiento operativo o de cualquier otra naturaleza que permita asegurar un correcto funcionamiento del servicio.<br></br>

8.2. La prestación del servicio queda sujeta a las disfunciones, anomalías, errores e interrupciones propias de las aplicaciones informáticas. En consecuencia, AIBUC no puede garantizar un uso ininterrumpido de los servicios. En ningún caso, AIBUC será responsable por cualquier daño o perjuicio como resultado de la indisponibilidad temporal del servicio.<br></br>

8.3. AiBUC integra vía API (Application Programming Interface o Interfaces de programación de aplicaciones) las aplicaciones de terceros (Azurre Microsoft, OpenAI, Google,etc), por lo que AIBUC no puede garantizar que alguna/s de las funcionalidades disponibles en AiBUC de cada una de esas empresas, deje/n de funcionar por cambios en las políticas internas de esos terceros, pues es cada una de las empresas titular de su respectivo negocio lo que decide qué funcionalidades ofrece a través de su respectiva API, sin que AIBUC tenga control alguno sobre las eventuales modificaciones en cada API de esos terceros. Por tanto, AIBUC no será responsable de los cambios que las empresas titulares de cada empresa integrada en AiBUC realicen con respecto a la eliminación y/o modificación de funcionalidades.<br></br>

9. Atención al usuario<br></br>
Puedes acceder a https://help.AiBUC.com/es/chatbot para obtener información sobre cómo AIBUC puede ayudarte.<br></br>

10. Modificación de las condiciones de contratación<br></br>
AIBUC se reserva la facultad de modificar o incluir cambios en los presentes Términos y Condiciones como consecuencia de la modificación, evolución y promulgación de leyes, reglamentos y normas de aplicación a la prestación del servicio y/o aspectos conexos a los mismos o relacionados con aspectos técnicos o tecnológicos que no han podido preverse en el momento de redacción de las presentes condiciones.<br></br>

11. Derechos y obligaciones de AIBUC<br></br>
11.1. Derechos:<br></br>

AIBUC tendrá derecho a:<br></br>

– Percibir el pago del precio que, en su caso, corresponda por los servicios prestados<br></br>

– Introducir cambios o mejoras tecnológicas en los servicios prestados.<br></br>

– A interrumpir el servicio para realizar todas aquellas tareas que entienda necesarias y/o convenientes con el fin de acometer mejoras y/o reestructuraciones en el mismo, así como para realizar operaciones de mantenimiento del servicio. En este último caso, AIBUC realizará sus mayores esfuerzos para notificarte, en la medida que ello sea posible y con una antelación razonable, aquellos períodos de tiempo en los que se interrumpirá el servicio y para que tales interrupciones te afecten de forma mínima.<br></br>

11.2. Obligaciones:<br></br>

AIBUC se compromete a prestar los servicios de acuerdo con los términos establecidos en las presentes condiciones.<br></br>

12. Derechos y obligaciones del/la Administrador/a(usuario)<br></br>
12.1. Derechos:<br></br>

Tendrás derecho a utilizar los servicios contratados conforme a lo previsto en las presentes condiciones.<br></br>

12.2. Obligaciones:<br></br>

Por la contratación de los servicios de AiBUC estarás obligado/a a:<br></br>

– Cumplir con las obligaciones de pago que en, en su caso, correspondan.<br></br>

– No perturbar, impedir, interferir, distorsionar o dañar los equipos o sistemas asignados de AIBUC o de terceros.<br></br>

– Observar y cumplir todas las normas de seguridad que AIBUC pueda imponer sobre la utilización de sus recursos y no utilizar los servicios para finalidades diferentes a las previstas por AIBUC ni para la realización de actividades contrarias a las leyes, a la moral, al orden público ni a utilizar los servicios con fines o efectos ilícitos, prohibidos, lesivos de derechos e intereses de terceros, declinando AIBUC cualquier responsabilidad que de ello se pudiera derivar.<br></br>

13. Política de Enlaces<br></br>
13.1. Enlaces desde otros sitios web:<br></br>

Si se pretende establecer un enlace desde una página web de otro portal de Internet a cualquiera de las páginas web de AiBUC deberán cumplirse las siguientes condiciones:<br></br>

– No se permite, la reproducción total o parcial de ninguno de los servicios ni contenidos del sitio web, ni el establecimiento de deep-links, ni enlaces IMG o de imagen, ni frames con el Sitio Web sin contar con previa autorización expresa y por escrito.<br></br>

– No se realizará ninguna manifestación falsa, inexacta o incorrecta sobre el Sitio Web, ni sobre los servicios o contenidos de este. Salvo aquellos signos que formen parte del enlace, la página web en la que se establezca no contendrá ninguna marca, nombre comercial, rótulo de establecimiento, denominación, logotipo, eslogan u otros signos distintivos pertenecientes a AIBUC, salvo autorización expresa de ésta.<br></br>

– El establecimiento del enlace no implicará la existencia de relación alguna entre AIBUC y el titular de la página web o del portal desde el cual se realice, ni el conocimiento y aceptación de AIBUC de los servicios y contenidos ofrecidos en dicha página web o portal.<br></br>

– AIBUC no será responsable de los contenidos o servicios puestos a disposición del público en la página web o portal desde el cual se realice el hipervínculo, ni de las informaciones y manifestaciones incluidas en los mismos.<br></br>

13.2. Enlaces hacia otras webs:<br></br>

El sitio web de AIBUC puede poner a disposición del usuario enlaces hacia otros sitios web gestionados y controlados por terceros, con la exclusiva función de facilitar a los usuarios la búsqueda de información, contenidos y servicios en Internet, sin que en ningún caso pueda considerarse que AIBUC comercializa, dirige, controla o hace propios los contenidos, servicios, informaciones y manifestaciones disponibles en dichos sitios web.<br></br>

AIBUC no asume ningún tipo de responsabilidad, siquiera de forma indirecta o subsidiaria, por los contenidos, informaciones, comunicaciones, opiniones o servicios enlazados desde esos sitios webs no gestionados por AIBUC y que resulten accesibles a través de la Web, ni garantiza la ausencia de virus u otros elementos en los mismos que puedan producir alteraciones en el sistema informático (hardware y ), en los documentos o los ficheros del usuario, excluyendo cualquier responsabilidad por los daños de cualquier clase causados al usuario por este motivo.<br></br>

14. Régimen de Responsabilidad<br></br>
14.1. Interrupciones en el funcionamiento de la Plataforma:<br></br>

AIBUC no puede garantizar, y no garantiza, que no se produzcan interrupciones o errores en el acceso a AiBUC. No obstante, existe el firme compromiso, tan pronto como AIBUC tenga conocimiento de tales incidencias, de llevar a cabo todas las actuaciones dirigidas a su restablecimiento o reparación, salvo la concurrencia de causas ajenas a AIBUC que lo imposibiliten o dificulten su ejecución.<br></br>

14.2. Exoneración de responsabilidad:<br></br>

AIBUC no asumirá responsabilidad alguna:<br></br>

– Por los problemas derivados de la falta de acceso o de los problemas inherentes a la conectividad a Internet o a las redes de electricidad cuando estas tuvieren su origen en causas ajenas a su control o a causas que no hubieran podido ser previstas por las partes o que, aun siendo previsibles, AIBUC haya realizado todos los esfuerzos razonables para evitarlas o que fueran consideradas como causas fortuitas o de fuerza mayor.<br></br>

– Por los daños y perjuicios de cualquier naturaleza que puedan causarse a tus sistemas físicos (Hardware) y lógicos () o a los ficheros o documentos almacenados en los mismos, como consecuencia de la presencia de virus en tu dispositivo o de un mal funcionamiento o uso de una versión no actualizada de tu navegador.<br></br>

– Por tus obligaciones legales para el desarrollo de tu actividad como gestor de planes de negocios.<br></br>

– Por la falta de veracidad, exactitud y/o vigencia de los datos que nos proporciones, ni de las consecuencias derivadas de la relación contractual entre tú y los clientes a los que gestiones sus planes de negocios haciendo uso de AiBUC.<br></br>

– Por las acciones u omisiones directamente imputables a ti o a terceros que dependan directamente de ti.<br></br>

– Por el hecho de que el servicio no cumpla con tus expectativas o no se ajuste a tus necesidades, por las ganancias o pérdidas patrimoniales en tu actividad, pues te corresponde valorar si AiBUC es útil a tus propósitos antes de su contratación.<br></br>

– Por el incumplimiento de las obligaciones que te incumben.<br></br>

– Por los errores en los que incurras en la utilización de AiBUC en tus planeaciones, programaciones y/o automatizaciones.<br></br>

– Por la eliminación o modificación de funcionalidades en las organizaciones conectadas a AiBUC realizadas por las empresas propietarias de las mismas.<br></br>

– Por la relación entre el/la Administrador/a y las Marcas que gestione.<br></br>

15. Propiedad intelectual e industrial<br></br>
15.1. Contenidos de AiBUC:<br></br>

La totalidad de los elementos y/o contenidos publicados en AiBUC han sido creados por AIBUC o esta dispone de una licencia o autorización para su utilización.<br></br>

Por tanto, todos esos elementos y/o contenidos (tales como: imágenes, audio, vídeo, o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, aplicaciones necesarias para su funcionamiento, acceso y uso, etc.) y los derechos de explotación sobre los mismos, son de titularidad exclusiva de AIBUC.<br></br>

Queda totalmente prohibido la reproducción, distribución, comunicación pública- incluida su modalidad de puesta a disposición – y la transformación, de la totalidad o parte de los contenidos de AiBUC, en cualquier soporte y por cualquier medio técnico, sin la previa autorización expresa y por escrito de AIBUC.<br></br>

En caso de realizar cualquiera de las acciones prohibidas señaladas, se estarán vulnerando esos derechos exclusivos de propiedad intelectual o industrial que le corresponden a AIBUC. Por tanto, te obligas a respetar tales derechos, absteniéndote además de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en las páginas titularidad de AIBUC.<br></br>

15.2. Contenidos generados por el usuario:<br></br>

Queda absolutamente prohibido que compartas o hagas cualquier otro tipo de uso de contenidos protegidos legalmente sin estar autorizado para ello, tanto por la legislación relativa a la propiedad intelectual, a la propiedad industrial o por cualquier otra que, en su caso, resulte de aplicación.<br></br>

AIBUC se reserva el derecho a ejercitar las oportunas acciones legales en caso de que incumplas lo anteriormente expuesto. AIBUC te ofrece la posibilidad de denunciar cualquier clase de vulneración de los derechos por cualquier usuario o tercero por la introducción de un determinado contenido en el Sitio Web, comunicando dicha circunstancia a AIBUC, mediante el envío de un correo electrónico a la dirección de correo electrónico antes indicada.<br></br>

16. Tratamiento de datos personales<br></br>
16.1. Tratamiento de datos como responsable del Tratamiento:<br></br>

Los datos personales que AIBUC trate como responsable del Tratamiento, lo serán conforme a lo previsto en la Política de Privacidad publicada en el Sitio Web y a la que nos remitimos en evitación de innecesarias repeticiones.<br></br>

AIBUC utiliza como protección de Datos a Microsoft Azurre y Open AI.<br></br>

16.2. Tratamiento de datos como Encargado de Tratamiento:<br></br>

16.2.1. Objeto del encargo de tratamiento:<br></br>

Mediante la contratación de los servicios de AiBUC se habilita a AIBUC para tratar por cuenta de el/la Administrador/a, los datos de carácter personal necesarios para prestar los servicios de AiBUC.<br></br>

16.2.2. Identificación de la información afectada:<br></br>

La información afectada sería la siguiente:<br></br>

Contactos en las cuentas de redes sociales de la/s Marca/a conectadas a AiBUC: datos del perfil público de usuario, datos proporcionados en mensajes directos.<br></br>

Terceros con acceso a la cuenta de el/la Administrador/a: nombre y correo electrónico.<br></br>

16.2.3. Duración:<br></br>

El acceso a datos personales responsabilidad de el/la Administrador/a o de las Marcas por el/la mismo/a gestionadas se producirá en tanto en cuanto esté vigente la contratación de los servicios de AiBUC para esa Marca en concreto.<br></br>

16.2.4. Obligaciones de AIBUC como encargada del tratamiento:<br></br> AIBUC y todo su personal se obligan a:<br></br>

a. Utilizar los datos personales objeto de tratamiento, o los recogidos por el/la Administrador/a a través de AiBUC, única y exclusivamente para la finalidad objeto del servicio contratado. En ningún caso podrán utilizar los datos para fines propios de AIBUC.<br></br>

b. Tratar los datos de acuerdo con las instrucciones de el/la Administrador/a de acuerdo a las funcionalidades ofrecidas por AiBUC.<br></br>

Si AIBUC considera que alguna de las instrucciones infringe el Reglamento General de Protección de Datos (RGPD) o cualquier otra disposición en materia de protección de datos de la Unión o de los Estados miembros, lo pondrá de inmediato en conocimiento de el/la Administrador/a.<br></br>

c. Llevar, por escrito, un registro de todas las categorías de actividades de tratamiento efectuadas por cuenta de el/la Administrador/a, que contenga:<br></br>

– El nombre y los datos de contacto de AIBUC y de cada responsable por cuenta del cual actúa.<br></br>

AIBUC puede comunicar los datos a otros encargados del tratamiento del mismo responsable del tratamiento, de acuerdo con las instrucciones de el/la Administrador/a. En este caso, el/la Administrador/a identificará, de forma previa y por escrito, la entidad a la que se deben comunicar los datos, los datos a comunicar y las medidas de seguridad a aplicar para proceder a la comunicación.<br></br>

Si AIBUC debe transferir datos personales a un tercer país o a una organización internacional, en virtud del Derecho de la Unión o de los Estados miembros que le sea aplicable, informará al/la Administrador/a de esa exigencia legal de manera previa, salvo que tal Derecho lo prohíba por razones importantes de interés público.<br></br>

f. Mantener el deber de secreto respecto a los datos de carácter personal a los que haya tenido acceso en virtud de la prestación del servicio, incluso después de que finalice su objeto.<br></br>

g. Garantizar que las personas autorizadas para tratar datos personales se comprometan, de forma expresa y por escrito, a respetar la confidencialidad y a cumplir las medidas de seguridad correspondientes, de las que hay que informarles convenientemente.<br></br>

h. Mantener a disposición de el/la Administrador/a la documentación acreditativa del cumplimiento de la obligación establecida en el apartado anterior.<br></br>

i. Garantizar la formación necesaria en materia de protección de datos personales de las personas autorizadas para tratar datos personales.<br></br>

Corresponde al/la Administrador/a comunicar las quiebras de la seguridad de los datos a la Autoridad de Protección de Datos y a los interesados, cuando sea probable que la quiebra suponga un alto riesgo para los derechos y las libertades de las personas físicas.<br></br>

j. Dar apoyo al/la Administrador/a en la realización de las consultas previas a la autoridad de control, cuando proceda.<br></br>

k. Poner a disposición de el/la Administrador/a toda la información necesaria para demostrar el cumplimiento de sus obligaciones, así como para la realización de las auditorías o las inspecciones que realice el/la Administrador/a u otro auditor autorizado por éste/a.<br></br>

m. En todo caso, AIBUC implantará mecanismos para:<br></br>

a) Garantizar la confidencialidad, integridad, disponibilidad y resiliencia permanentes de los sistemas y servicios de tratamiento.<br></br>

b) Restaurar la disponibilidad y el acceso a los datos personales de forma rápida, en caso de incidente físico o técnico.<br></br>

c) Verificar, evaluar y valorar, de forma regular, la eficacia de las medidas técnicas y organizativas implantadas para garantizar la seguridad del tratamiento.<br></br>

n. Una vez cumplida la prestación cesar en el acceso a los datos y si procede, devolver a el/la Administrador/a, los datos de carácter personal y soportes donde consten, con el borrado total de los datos existentes en los equipos informáticos utilizados por el AIBUC. No obstante, AIBUC puede conservar una copia, con los datos debidamente bloqueados, mientras puedan derivarse responsabilidades de la ejecución de la prestación.<br></br>

16.2.5. Obligaciones de el/la Administrador/a:<br></br>

Corresponde al/la Administrador/a:<br></br>

a. Poner a disposición de AIBUC los datos necesarios para la prestación del servicio contratado.<br></br>

b. Corresponde a el/la Administrador/a facilitar el derecho de información en el momento de la recogida de los datos de las Marcas.<br></br>

c. Realizar, en su caso, una evaluación del impacto en la protección de datos personales de las operaciones de tratamiento a realizar por AIBUC, así como las consultas previas ante la autoridad de control que corresponda.<br></br>

17. Subcontratación<br></br>
AIBUC podrá contratar y/o subcontratar con terceros todo lo que estime oportuno en relación con la realización por los mismos de cualesquiera o todas las actividades necesarias para el mantenimiento, actualización, mejora o prestación de los servicios.<br></br>

18. Modificación de los servicios<br></br>
AIBUC se reserva la facultad de modificar los servicios a fin de adaptarlos:<br></br>

a) A la evolución técnica del mercado<br></br>

b) A cualquier mejora técnica, funcional y operativa que permita una mejora en la prestación de los mismos.<br></br>

c) A la evolución de las leyes, reglamentos y normas de aplicación a la prestación de los Servicios y/o aspectos conexos a los mismos. Asimismo, AIBUC se reserva la facultad de modificar unilateralmente y sin previo aviso, la presentación, configuración y operatividad del servicio siempre y cuando dicha modificación no afecte la funcionalidad ni los compromisos del Servicio adquiridos ni suponga ningún incremento de los precios acordados.<br></br>

19. Derecho de desistimiento:<br></br>
El/la Administrador/a dispone de un plazo de desistimiento de los servicios contratados de 5 días consecutivos a contar desde la fecha de la primera contratación de cualquier Plan de AiBUC. Dicho derecho de desistimiento no se aplica a las renovaciones automáticas.<br></br>

Si deseas desistir de la contratación deberás notificar tu decisión a AIBUC en tal sentido a través de una declaración inequívoca (por ejemplo, una comunicación remitida por correo electrónico). A tal fin, podrás utilizar el modelo de formulario de desistimiento que figura a continuación, aunque su uso no es obligatorio:<br></br>

Modelo de formulario de desistimiento<br></br>

(puedes copiar y pegar el siguiente formulario en un correo electrónico y completar tus datos si deseas desistir del contrato):<br></br>

A la atención de AIBUC LLC, con domicilio Aibuc LLC, con domicilio 5118 N 56TH STREET STE 111, TAMPA, 33610, HILLSBOROUGH, FLORIDA. Correo electrónico de contacto: aibucllc@gmail.com<br></br>

Por la presente les comunico que desisto de la contratación del Plan _________ de AiBUC que se realizó con fecha ___/___/20__.<br></br>

Nombre y apellidos de el/la Administrador/a: ________________________________<br></br>

Correo electrónico de el/la Administrador/a ________________________________<br></br>

Fecha de la comunicación del desistimiento: ___/___/20__<br></br>

En caso de desistimiento ejercitado y comunicado y una vez recibida tal comunicación, AIBUC procederá a la devolución de los importes satisfechos con ocasión de la contratación del servicio sin ninguna demora indebida.<br></br>

20. Legislación aplicable y jurisdicción competente<br></br>
Los presentes términos y condiciones se regirán por la legislación Argentina, aunque la elección de ley no impide invocar la protección que ofrecen las disposiciones imperativas de la Ley de la residencia habitual en el caso de que se ostente la condición de consumidor o usuario, aunque AiBUC se dirige a empresas y/o profesionales que lo contratan con un propósito relacionado con su actividad comercial, empresarial, estudio, oficio o profesión.<br></br>

Cualquier divergencia y/o desacuerdo con respecto a lo establecido en las presentes condiciones se someterá a los Juzgados y Tribunales La Plata, Buenos Aires, Argentina o TAMPA, Florida.<br></br>
</p>

</p>
          {/* Contenido de los términos y condiciones */}
        </div>
        <div className="modal-footer">
          <div className="slider-container">
            <button 
              className={`slider-button ${termsAccepted ? 'accepted' : ''}`} 
              onClick={() => setTermsAccepted(!termsAccepted)}
            >
              <span className="slider-circle"></span>
            </button>
            <p>{termsAccepted ? "Términos aceptados" : "Toca para aceptar"}</p>
          </div>
          <button onClick={() => setShowModal(false)} className="closebutton">Cerrar</button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="register-page">
      <div className="register-form">
        <h1>Crea tu cuenta</h1>
        <form onSubmit={handleRegisterSubmit}>

          <div className="form-group">
            <FaRegUser className="input-icon" />
            <input 
              type="text" 
              name="name" 
              value={formData.name} 
              onChange={handleChange} 
              required 
              placeholder="Nombre completo" 
            />
          </div>
          <div className="form-group">
            <FaRegEnvelope className="input-icon" />
            <input 
              type="email" 
              name="email" 
              value={formData.email} 
              onChange={handleChange} 
              required 
              placeholder="Correo electrónico" 
            />
          </div>
          <div className="form-group">
            <FaRegUser className="input-icon" />
            <input 
              type="text" 
              name="username" 
              value={formData.username} 
              onChange={handleChange} 
              required 
              placeholder="Nombre de usuario" 
            />
          </div>
          <div className="form-group">
            <MdOutlineLock className="input-icon" />
            <input 
              type={showPassword ? 'text' : 'password'} 
              name="password" 
              value={formData.password} 
              onChange={handleChange} 
              required 
              placeholder="Contraseña" 
            />
            <button type="button" className="toggle-password" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <RiEyeCloseFill /> : <FaEye />}
            </button>
          </div>
          <div className="form-group">
            <MdOutlineLock className="input-icon" />
            <input 
              type={showConfirmPassword ? 'text' : 'password'} 
              name="confirmPassword" 
              value={formData.confirmPassword} 
              onChange={handleChange} 
              required 
              placeholder="Confirmar contraseña" 
            />
            <button type="button" className="toggle-password" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
              {showConfirmPassword ? <RiEyeCloseFill /> : <FaEye />}
            </button>
          </div>
          <div className="terms-agreement">
            <input 
              type="checkbox" 
              id="terms" 
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
            />
            <label htmlFor="terms">He leído y acepto los <span className="terms-link" onClick={() => setShowModal(true)}>términos y condiciones</span></label>
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" className="submit-btn" disabled={!termsAccepted}>
            <FaUserPlus className="btn-icon" /> Crear cuenta
          </button>
        </form>
        <Link to="/login" className="login-link">¿Ya tienes cuenta? Inicia sesión</Link>
      </div>
      {showModal && <TermsModal />}
    </div>
  );
};

export default Register;