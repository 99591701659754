import React, { useEffect, useState } from 'react';
import { FaEnvelope, FaLock, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../UI/Sidebar';
import './Profile.css';

const Profile = () => {
  const [user, setUser] = useState({ name: '', email: '', username: '', subscription_status: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nuevoNombre, setNuevoNombre] = useState('');
  const [nuevoEmail, setNuevoEmail] = useState('');
  const [nuevaContraseña, setNuevaContraseña] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  useEffect(() => {
    const obtenerPerfil = async () => {
      try {
        if (!token) throw new Error('No se encontró el token');

        const respuesta = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/me`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!respuesta.ok) {
          throw new Error('La respuesta de la red no fue correcta');
        }

        const datos = await respuesta.json();
        setUser(datos);
        setNuevoNombre(datos.name);
        setNuevoEmail(datos.email);
        setIsLoading(false);
      } catch (error) {
        console.error('Error al obtener el perfil del usuario:', error);
        setError(error.message);
        setIsLoading(false);
      }
    };

    obtenerPerfil();
  }, [token]);

  const manejarActualizacion = async (url, cuerpo, mensajeExito) => {
    setError(null);
    try {
      const respuesta = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(cuerpo)
      });

      const datos = await respuesta.json();
      if (!respuesta.ok) {
        throw new Error(datos.error || 'Fallo al actualizar');
      }

      setUser(prevUser => ({ ...prevUser, ...cuerpo }));
      alert(mensajeExito);
    } catch (error) {
      setError(error.message);
    }
  };

  const deleteAccount = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/me`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });

      if (!response.ok) {
        throw new Error('Error al eliminar la cuenta');
      }

      const data = await response.json();
      setShowWarning(false);
      alert("Tu suscripción ha sido cancelada con exito");
      localStorage.removeItem('token');
      navigate('/login');

    } catch (error) {
      console.error('Error:', error.message);
    }
  };


  const manejarActualizacionNombre = () => {
    manejarActualizacion(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/me`, { name: nuevoNombre }, 'Nombre actualizado con éxito');
  };

  const manejarActualizacionEmail = () => {
    manejarActualizacion(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/me`, { email: nuevoEmail }, 'Email actualizado con éxito');
  };

  const manejarActualizacionContraseña = () => {
    manejarActualizacion(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/me/password`, { new_password: nuevaContraseña }, 'Contraseña actualizada con éxito');
  };

  if (isLoading) return <div className="loading">Cargando...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="profile-page">
      <Sidebar />
      <div className="profile-content">
        <div className="profile-card">
          <h1>Configuración del Perfil</h1>
          <p>Administra tu información personal y la configuración de tu cuenta.</p>

          <div className="profile-section">

            <div className="profile-field">
              <div className="field-header">
                <FaUser />
                <label>Nombre</label>
              </div>
              <input
                type="text"
                value={nuevoNombre}
                onChange={(e) => setNuevoNombre(e.target.value)}
              />
              <button onClick={manejarActualizacionNombre} className="edit-btn">Cambiar</button>
            </div>

            <div className="profile-field">
              <div className="field-header">
                <FaEnvelope />
                <label>Email</label>
              </div>
              <input
                type="email"
                value={nuevoEmail}
                onChange={(e) => setNuevoEmail(e.target.value)}
              />
              <button onClick={manejarActualizacionEmail} className="edit-btn">Cambiar</button>
            </div>

            <div className="profile-field">

            </div>
          </div>

          <div className="profile-section">
            <h2>Configuración de la Cuenta</h2>
            <p>Administra tu cuenta, incluyendo contraseña y eliminación.</p>

            <div className="profile-field">
              <div className="field-header">
                <FaLock />
                <label>Contraseña</label>
              </div>
              <input
                type="password"
                value={nuevaContraseña}
                onChange={(e) => setNuevaContraseña(e.target.value)}
                placeholder="Ingresa nueva contraseña"
              />
              <button onClick={manejarActualizacionContraseña} className="edit-btn">Cambiar</button>
            </div>

            <div className="profile-field">
              <button className="delete-btn" onClick={() => setShowWarning(true)}>Eliminar Cuenta</button>
            </div>
            {showWarning && (
              <div className="warning">
                <p>¿Estás seguro de que deseas eliminar tu cuenta? Esta acción no se puede deshacer.</p>
                <div className="downgrade-buttons">
                  <button className="cancel-subscription" onClick={deleteAccount}>Sí, eliminar mi cuenta</button>
                  <button className="keep-subscription" onClick={() => setShowWarning(false)}>No, mantener mi cuenta</button>
                </div>
              </div>
            )}
          </div>
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default Profile;